import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
  Card,
  Row,
  Col,
  Space,
  Button,
  message,
  Input,
  Typography,
  Image,
  Table,
  Upload,
  Form,
  Select,
} from 'antd';

import {
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import CustomTitle from '@components/Title';

import fetchRequest, { fetchFormData } from '@utils/fetcher';
import { GALERI } from '@constants/urls';
import { GalleryItemProps, CategoryProps } from '@customTypes/info';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const UpsertGallery = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [itemList, setItemList] = useState<GalleryItemProps[]>([]);
  const [photos, setPhotos] = useState<any[]>([]);
  const [categoryList, setCategoryList] = useState<CategoryProps[]>([]);
  const [category, setCategory] = useState<string[]>([]);

  const [videoForm] = Form.useForm();

  const getCurrentData = async (id: string) => {
    try {
      await fetchRequest({ method: 'GET', path: 'gallery/id?id=' + id }).then(
        (response) => {
          if (!response.error) {
            setTitle(response.title || '');
            setDesc(response.content || '');
            setItemList(response.items || []);
            setCategory(response.categories || []);
          } else {
            message.error(response.error.message);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getCategoryList = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'category?types=SubCategory%2CCategory',
      }).then((response) => {
        setCategoryList(response.Category || []);
      });
    } catch (error) {}
  };

  useEffect(() => {
    getCategoryList();

    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const galleryId = path[path.indexOf('edit') + 1];

      setId(galleryId);

      getCurrentData(galleryId);
    }
  }, []);

  const handleDelete = async (itemId: string) => {
    try {
      await fetchRequest({
        method: 'DELETE',
        path: 'gallery/item?galleryId=' + id + '&itemId=' + itemId,
      }).then((response) => {
        if (!response.error) {
          message.success('Berhasil menghapus item');
          getCurrentData(id);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: 'Konten',
      key: 'url',
      dataIndex: 'url',
      render: (text: string, record: GalleryItemProps) => {
        if (record.itemType === 'Photo') {
          return <Image src={record.signedUrl} />;
        } else {
          return text;
        }
      },
    },
    {
      title: 'Aksi',
      key: 'id',
      dataIndex: 'id',
      render: (text: string) => (
        <Button danger onClick={() => handleDelete(text)}>
          Hapus
        </Button>
      ),
    },
  ];

  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setPhotos(fileList);
  };

  const handleSubmit = async () => {
    const videos = videoForm.getFieldValue('videos');

    const formData = new FormData();

    const items = {
      items: itemList,
    };

    if (videos) {
      const videoList = videos.map(
        (vd: Record<string, string>) => vd['Link Video Youtube']
      );
      const newItemList = videoList.map((v: string) => {
        return {
          id: 'new',
          itemType: 'Video',
          url: v,
        };
      });
      items.items.push(...newItemList);
    }

    formData.append('title', title);
    formData.append('content', desc);
    if (category) {
      formData.append('categories', String(category));
    }

    if (photos) {
      photos.forEach((pt) => {
        formData.append('files', pt.originFileObj);
        const newPhotoItem = {
          id: 'new',
          itemType: 'Photo',
          url: '',
        };
        items.items.push(newPhotoItem as GalleryItemProps);
      });
    }

    formData.append('items', JSON.stringify(items));

    if (id) {
      formData.append('id', id);
    }

    console.log(items);

    try {
      await fetchFormData({
        method: id ? 'PATCH' : 'POST',
        path: 'gallery',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(GALERI);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle
              name="Album"
              url={GALERI}
              toolTip="Menerima jenis file: .jpg, .png, dan .jpeg. Total maksimal file yang dapat diunggah adalah 25mb."
            />
            <Title level={5}>Judul Album</Title>
            <TextArea
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              rows={2}
            />
            <Title level={5}>Deskripsi Album</Title>
            <TextArea
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              rows={2}
            />
            <Title level={5}>Tema Album</Title>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              value={category}
              onChange={(value) => setCategory(value)}
            >
              {categoryList.map((cat) => (
                <Option key={cat.id} value={cat.name}>
                  {cat.name}
                </Option>
              ))}
            </Select>
            <Table columns={columns} dataSource={itemList} pagination={false} />
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Upload
                  listType="picture"
                  accept=".png, .jpg, .jpeg"
                  fileList={photos}
                  onChange={handleImageUpload}
                  beforeUpload={()=> false}
                >
                  <Button icon={<UploadOutlined />}>Tambah Foto</Button>
                </Upload>
              </Col>
              <Col span={12}>
                <Form name="addVideos" autoComplete="off" form={videoForm}>
                  <Form.List name="videos">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{ display: 'flex', marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, 'Link Video Youtube']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Link video harus diisi',
                                },
                              ]}
                            >
                              <Input placeholder="Link video youtube" />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Tambah Video
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </Form>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default UpsertGallery;
