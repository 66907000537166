import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import ReactQuill from 'react-quill';

import { Card, Row, Col, Space, Button, message } from 'antd';

import CustomTitle from '@components/Title';

import { NEWSLETTER } from '@constants/urls';

import 'react-quill/dist/quill.snow.css';
import fetchRequest, { fetchFormData } from '@utils/fetcher';

const UpdateHeaderFooter = () => {
  const [content, setContent] = useState('');
  const [id, setId] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append('title', id);
    formData.append('type', id);
    formData.append('content', content);
    formData.append('id', id);

    try {
      await fetchFormData({
        method: 'PATCH',
        path: 'newsletter',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(NEWSLETTER);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getContent = async (id: string) => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'newsletter/id?id=' + id,
      }).then((response) => {
        if (!response.error) {
          setContent(response.content || '');
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const newsletterId = path[path.indexOf('edit') + 1];

      setId(newsletterId);

      getContent(newsletterId);
    }
  }, []);
  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle name={id + ' Newsletter'} url={NEWSLETTER} />
            <ReactQuill
              value={content}
              onChange={(value) => setContent(value)}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default UpdateHeaderFooter;
