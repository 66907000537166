import { useNavigate } from 'react-router';

import { Space, Button, Typography, Tooltip } from 'antd';
import { LeftOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;

interface CustomTitleProps {
  name: string;
  url: string;
  toolTip?: string;
}

const CustomTitle = (props: CustomTitleProps) => {
  const { name, url, toolTip = '' } = props;

  const navigate = useNavigate();

  return (
    <Space direction="horizontal" align="end">
      <Button
        icon={<LeftOutlined />}
        danger
        type="link"
        onClick={() => navigate(url)}
      ></Button>
      <Title level={4} style={{ marginBottom: 0 }}>
        {name}
      </Title>
      {toolTip && (
        <Tooltip title={toolTip} placement="bottom">
          <Button icon={<InfoCircleOutlined />} type="text" />
        </Tooltip>
      )}
    </Space>
  );
};

export default CustomTitle;
