import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, Tag, message } from 'antd';
import dayjs from 'dayjs';

import CategorySection from '@components/CategorySection';

import type { InfoProps } from '@customTypes/info';
import type { QuickLinkProps } from '@customTypes/quickLink';

import DeleteButton from '@components/DeleteButton';

import fetchRequest from '@utils/fetcher';
import {
  ADD_BERITA,
  ADD_KATEGORI_BERITA,
  EDIT_BERITA,
  EDIT_KATEGORI_BERITA,
} from '@constants/urls';

const { Title } = Typography;

const News = () => {
  const [newsList, setNewsList] = useState<InfoProps[]>([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);

  const navigate = useNavigate();

  const handleDelete = async (id: string) => {
    try {
      await fetchRequest({ method: 'DELETE', path: 'info?id=' + id }).then(
        (response) => {
          if (!response.error) {
            getNewsList();
          } else {
            message.error(response.error.message);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const articleColumns = [
    {
      title: 'Tanggal Unggah',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <p>{dayjs(text).format('DD/MM/YYYY')}</p>,
    },
    {
      title: 'Judul Artikel',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Tema',
      dataIndex: 'categories',
      key: 'categories',
      render: (text: string[]) =>
        text.map((t, i) => (
          <Tag key={i} color="red">
            {t}
          </Tag>
        )),
    },
    {
      title: 'Nama Penulis',
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: 'Likes',
      dataIndex: 'likes',
      key: 'likes',
    },
    {
      title: 'Views',
      dataIndex: 'views',
      key: 'views',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_BERITA + '/' + record.id)}
          >
            Ubah
          </Button>
          <DeleteButton onDelete={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];

  const getNewsList = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'info?types=news' }).then(
        (response) => {
          if (Array.isArray(response)) {
            setNewsList(response);
          }
        }
      );
    } catch (error) {}
  };

  const getQuickLinkList = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'category?types=QuickLink',
      }).then((response) => {
        if (!response.error) {
          setQuickLinkList(response.QuickLink || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getNewsList();
    getQuickLinkList();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Berita
            </Title>
            <Button onClick={() => navigate(ADD_BERITA)}>Tambah Baru</Button>
          </Space>
        }
      >
        <Table
          columns={articleColumns}
          dataSource={newsList}
          pagination={{
            defaultCurrent: 1,
            total: newsList.length,
            pageSize: 5,
          }}
        />
      </Card>
      <CategorySection
        data={quickLinkList
          .filter((ql) => ql.pageName === 'News')
          .map((ql, i) => ({ ...ql, key: i + 1 }))}
        addLinkUrl={ADD_KATEGORI_BERITA}
        editLinkUrl={EDIT_KATEGORI_BERITA}
        onRefetch={getQuickLinkList}
      />
    </Space>
  );
};

export default News;
