import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card, Typography, Button, Space, Table, Popover, message } from 'antd';

import fetchRequest from '@utils/fetcher';

const { Title } = Typography;

interface BlacklistProps {
  email: string;
  reason: string;
}

const BlacklistCard = () => {
  const navigate = useNavigate();

  const [blacklistData, setBlacklistData] = useState<BlacklistProps[]>([]);

  const getData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'newsletter/blacklisted',
      }).then((response) => {
        if (!response.error) {
          setBlacklistData(response || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Alasan',
      dataIndex: 'reason',
      key: 'reason',
    },
  ];

  return (
    <Card
      title={
        <Space align="end">
          <Title level={4} style={{ marginBottom: 0 }}>
            Member yang Tidak Mendapatkan Newsletter
          </Title>
        </Space>
      }
    >
      <Table columns={columns} dataSource={blacklistData} pagination={false} />
    </Card>
  );
};

export default BlacklistCard;
