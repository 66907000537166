import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, message } from 'antd';

import CategorySection from '@components/CategorySection';

import {
  ADD_KATEGORI_ALF,
  EDIT_DESKRIPSI_ALF,
  EDIT_JAJARAN_AHLI,
  EDIT_KATEGORI_ALF,
} from '@constants/urls';

import type { ALFProps } from '@customTypes/aboutUs';
import type { QuickLinkProps } from '@customTypes/quickLink';

import fetchRequest from '@utils/fetcher';
import PreviewImage from '@components/PreviewImage';
import DeleteButton from '@components/DeleteButton';

const { Title } = Typography;

const ALFPage = () => {
  const [descData, setDescData] = useState<ALFProps | {}>({});
  const [expertsData, setExpertsData] = useState<ALFProps[]>([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);

  const navigate = useNavigate();

  const handleDelete = async (id: string) => {
    try {
      await fetchRequest({ method: 'DELETE', path: 'resource?id=' + id }).then(
        (response) => {
          if (!response.error) {
            getALFData();
          } else {
            message.error(response.error.message);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const descColumns = [
    {
      title: 'Deskripsi',
      dataIndex: 'content',
      key: 'content',
      render: (text: string) => (
        <div dangerouslySetInnerHTML={{ __html: text }} />
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      render: () => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_DESKRIPSI_ALF)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];

  const expertsColumns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Foto',
      dataIndex: 'url',
      render: (_: string, record: any) => (
        <PreviewImage
          key="key"
          buttonName="Lihat Foto"
          mediaUrl={record.url}
        ></PreviewImage>
      ),
    },
    {
      title: 'Nama',
      dataIndex: 'title',
    },
    {
      title: 'Jabatan',
      dataIndex: 'otherData',
      render: (_: string, record: any) => <>{record.otherData.position}</>,
    },
    {
      title: 'Narasi',
      dataIndex: 'content',
      render: (_: string, record: any) => (
        <div dangerouslySetInnerHTML={{ __html: record.content }}></div>
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_JAJARAN_AHLI + '/' + record.id)}
        >
          Ubah
        </Button>
          <DeleteButton onDelete={() => handleDelete(record.id)} />
          </Space>

      ),
      width: 150,
    },
  ];

  const getALFData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/about-us/alf',
      }).then((response) => {
        if (!response.error) {
          setDescData(response.description || {});
          setExpertsData(response.astagatraLeadersForum || {});
          setQuickLinkList(response.quickLinks || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getALFData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title={<Title level={4}>Deskripsi</Title>}>
        <Table
          columns={descColumns}
          dataSource={[descData]}
          pagination={false}
        />
      </Card>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Jajaran Ahli
            </Title>
            <Button onClick={() => navigate(EDIT_JAJARAN_AHLI)}>
              Tambah Baru
            </Button>
          </Space>
        }
      >
        <Table
          columns={expertsColumns}
          dataSource={expertsData}
          pagination={false}
        />
      </Card>
      <CategorySection
        data={quickLinkList
          .filter((ql) => ql.pageName === 'AstagatraLeadersForum')
          .map((ql, i) => ({ ...ql, key: i + 1 }))}
        addLinkUrl={ADD_KATEGORI_ALF}
        editLinkUrl={EDIT_KATEGORI_ALF}
        onRefetch={getALFData}
      />
    </Space>
  );
};

export default ALFPage;
