import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import ReactQuill from 'react-quill';

import {
  Card,
  Row,
  Col,
  Space,
  Typography,
  Input,
  Button,
  Select,
  Upload,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { BERITA } from '@constants/urls';

import CustomTitle from '@components/Title';

import type { CategoryProps } from '@customTypes/info';

import fetchRequest, { fetchFormData } from '@utils/fetcher';

import 'react-quill/dist/quill.snow.css';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const UpsertNews = () => {
  const [authorName, setAuthorName] = useState('');
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [header, setHeader] = useState<any[]>([]);
  const [content, setContent] = useState('');
  const [categoryList, setCategoryList] = useState<CategoryProps[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<CategoryProps[]>([]);
  const [summary, setSummary] = useState('');
  const [id, setId] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setHeader(fileList);
  };

  const handleSubmit = async () => {
    const method = id ? 'PATCH' : 'POST';

    const formData = new FormData();

    formData.append('summary', summary);
    formData.append('author', authorName);
    formData.append('title', title);
    formData.append('content', content);
    formData.append('type', 'news');
    if (category) {
      category.forEach((cat) => {
        formData.append('categories', cat);
      });
    }
    if (subCategory) {
      subCategory.forEach((cat) => {
        formData.append('subCategories', cat);
      });
    }
    if (header) {
      formData.append('headerFile', header[0]?.originFileObj || header[0]?.url);
    }
    if (id) {
      formData.append('id', id);
    }

    try {
      await fetchFormData({
        method: method,
        path: 'info',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(BERITA);
        } else {
          if (Array.isArray(response.message)) {
            response.message.map((msg: string) => message.error(msg));
          } else {
            message.error(response.message);
          }
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getCategoryList = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'category?types=SubCategory%2CCategory',
      }).then((response) => {
        setCategoryList(response.Category || []);
        setSubCategoryList(response.SubCategory || []);
      });
    } catch (error) {}
  };

  const getCurrentData = async (id: string) => {
    try {
      await fetchRequest({ method: 'GET', path: 'info/id?id=' + id }).then(
        (response) => {
          if (!response.error) {
            setTitle(response.title || '');
            setAuthorName(response.author || '');
            setCategory(
              response.categories && response.categories[0] !== ''
                ? response.categories
                : []
            );
            setSubCategory(
              response.subCategories && response.subCategories[0] !== ''
                ? response.subCategories
                : []
            );
            setSummary(response.summary || '');
            if (response.headerUrl) {
              setHeader([
                {
                  uid: '-1',
                  name: title + '.png',
                  status: 'done',
                  url: response.headerUrl,
                },
              ]);
            }
            setContent(response.content || '');
          } else {
            message.error(response.error.message);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getCategoryList();

    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const newsId = path[path.indexOf('edit') + 1];

      setId(newsId);

      getCurrentData(newsId);
    }
  }, []);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle
              name="Berita"
              url={BERITA}
              toolTip="Menerima jenis file .jpg, .jpeg, dan .png. Total maksimal file yang dapat diunggah adalah 25mb."
            />
            <Title level={5}>Header Berita</Title>
            <Upload
              listType="picture"
              maxCount={1}
              accept=".png, .jpg, .jpeg"
              fileList={header}
              onChange={handleImageUpload}
              beforeUpload={()=> false}
           >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Title level={5}>Judul Berita</Title>
            <TextArea
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              rows={2}
            />
            <Title level={5}>Ringkasan</Title>
            <TextArea
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              rows={2}
            />
            <Title level={5}>Nama Penulis</Title>
            <Input
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
            />
            <Title level={5}>Tema Berita</Title>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              value={category}
              onChange={(value) => setCategory(value)}
            >
              {categoryList.map((cat) => (
                <Option key={cat.id} value={cat.name}>
                  {cat.name}
                </Option>
              ))}
            </Select>
            <Title level={5}>Sub-tema Berita</Title>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              value={subCategory}
              onChange={(value) => setSubCategory(value)}
            >
              {subCategoryList.map((cat) => (
                <Option key={cat.id} value={cat.name}>
                  {cat.name}
                </Option>
              ))}
            </Select>
            <Title level={5}>Konten Berita</Title>
            <ReactQuill
              value={content}
              onChange={(value) => setContent(value)}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default UpsertNews;
