import { useEffect, useState } from 'react';

import { message, Space } from 'antd';

import ReqeustMemberCard from '@routes/Member/components/RequestMemberCard';

import fetchRequest from '@utils/fetcher';

import { MemberRequestType } from '@customTypes/member';

const NewMember = () => {
  const [requestData, setRequestData] = useState<MemberRequestType[]>([]);

  const getRequestData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'user-member/membership-request?type=REGISTRATION',
      }).then((response) => {
        if (!response.error) {
          setRequestData(response || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getRequestData();
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <ReqeustMemberCard
        data={requestData}
        onRefresh={getRequestData}
        type="REGISTRATION"
      />
    </Space>
  );
};

export default NewMember;
