import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  Space,
  Card,
  Table,
  Button,
  Typography,
  message,
  Input,
  Row,
  Col,
} from 'antd';

import { Cookies } from 'react-cookie';

import fetchRequest from '@utils/fetcher';

const { Title } = Typography;

type SocialMedia = {
  type: string;
  content: string;
};

const ChangePassword = () => {
  const [oldPassword, setOld] = useState('');
  const [newPassword, setNew] = useState('');

  const handleSubmit = async () => {
    const cookies = new Cookies();

    const token = cookies.get('admin-token') || '';

    const param = {
      oldPassword,
      newPassword,
      accessToken: token,
    };

    try {
      await fetchRequest({
        method: 'PATCH',
        path: 'member/password',
        data: param,
        changeUrl: true,
      }).then((response) => {
        if (!response.error) {
          message.success('Password Anda telah berhasil diubah');
        } else {
          message.error(response.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card>
        <Title level={4} style={{ marginBottom: 0 }}>
          Ganti Password
        </Title>
        <Title level={5}>Password Lama</Title>
        <Input.Password
          value={oldPassword}
          onChange={(e) => setOld(e.target.value)}
        />
        <Title level={5}>Password Baru</Title>
        <Input.Password
          value={newPassword}
          onChange={(e) => setNew(e.target.value)}
        />
        <Row justify="end" style={{ marginTop: '20px' }}>
          <Col>
            <Button type="primary" danger onClick={() => handleSubmit()}>
              Simpan Perubahan
            </Button>
          </Col>
        </Row>
      </Card>
    </Space>
  );
};

export default ChangePassword;
