import { useEffect, useState } from 'react';

import {
  Input,
  Row,
  Col,
  Card,
  Space,
  Typography,
  Button,
  message,
  Avatar,
  Tooltip,
  Upload,
} from 'antd';

import { InfoOutlined, UploadOutlined } from '@ant-design/icons';

import CustomTitle from '@components/Title';

import { ALF } from '@constants/urls';
import { useNavigate } from 'react-router-dom';
import fetchRequest, { fetchFormData } from '@utils/fetcher';
import ReactQuill from 'react-quill';

const { Title } = Typography;

const EditALFPeople = () => {
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [position, setPosition] = useState('');
  const [mediaUrl, setMediaUrl] = useState<any[]>([]);

  const navigate = useNavigate();

  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setMediaUrl(fileList);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append('content', content);
    formData.append('title', title);
    formData.append('position', position);
    if (mediaUrl) {
      formData.append('file', mediaUrl[0]?.originFileObj || mediaUrl[0]?.url);
    }
    if (id) {
      formData.append('id', id);
    }

    try {
      await fetchFormData({
        method: 'POST',
        path: 'resource/about-us/alf/people',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(ALF);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getManagementData = async (id: string) => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource?id='+id,
      }).then((response) => {
        setContent(response.content);
        setPosition(response.otherData.position)
            setTitle(response.title);
            setMediaUrl([
              {
                uid: '-1',
                name: title + '.png',
                status: 'done',
                url: response.url,
              },
            ]);
          });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const isEdit =
    location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

  if (isEdit) {
    const path = location.pathname.split('/');
    const managementId = path[path.indexOf('edit') + 1];

    setId(managementId);
    getManagementData(managementId);
  }
  }, []);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space align="baseline">
              <CustomTitle name="Astagatra Leaders Forum" url={ALF} />
              <Tooltip title="Menerima jenis file: .jpg, .jpeg, .png. Untuk icon transparan gunakan .png">
                <Avatar
                  size={'small'}
                  icon={<InfoOutlined />}
                  style={{ backgroundColor: '#8C8C8C' }}
                ></Avatar>
              </Tooltip>
            </Space>
            <Title level={5}>Foto</Title>
            <Upload
              listType="picture"
              maxCount={1}
              accept=".png, .jpg, .jpeg"
              fileList={mediaUrl}
              onChange={handleImageUpload}
              beforeUpload={()=> false}
            >
              <Button icon={<UploadOutlined />}>Unggah Icon</Button>
            </Upload>
            <Title level={5}>Nama</Title>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Title level={5}>Jabatan</Title>
            <Input
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
            <Title level={5}>Narasi</Title>
            <ReactQuill
              value={content}
              onChange={(value) => setContent(value)}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditALFPeople;
