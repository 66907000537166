import { Space } from 'antd';

import BlacklistCard from './BlacklistCard';
import ContentCard from './ContentCard';
import HeaderFooterCard from './HeaderFooterCard';

const Newsletter = () => {
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <HeaderFooterCard />
      <ContentCard />
      <BlacklistCard />
    </Space>
  );
};

export default Newsletter;
