import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import './styles.css';

import { MENUS } from '@constants/menus';
import { MENU_ICON } from '@constants/menus/icons';

const { SubMenu } = Menu;

const Sidebar = () => {
  const navigate = useNavigate();

  const handleMenuOnClik = (url: string) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <Menu
      style={{ height: '100%', borderRight: 0, width: '100%' }}
      defaultSelectedKeys={['1']}
      defaultOpenKeys={['sub1']}
      mode="inline"
    >
      {MENUS.map((menu, i) => {
        if (menu.submenu.length > 0) {
          return (
            <SubMenu
              key={menu.name}
              icon={
                <img
                  src={MENU_ICON[menu.name as keyof typeof MENU_ICON]}
                  alt={menu.name}
                />
              }
              title={menu.name}
            >
              {menu.submenu.length > 0 && (
                <Menu.ItemGroup key={`submenu-${i}`}>
                  {menu.submenu.map((submenu) => (
                    <Menu.Item
                      key={submenu.url}
                      onClick={() => handleMenuOnClik(submenu.url)}
                    >
                      {submenu.name}
                    </Menu.Item>
                  ))}
                </Menu.ItemGroup>
              )}
            </SubMenu>
          );
        } else {
          return (
            <Menu.Item
              key={menu.name}
              icon={
                <img
                  src={MENU_ICON[menu.name as keyof typeof MENU_ICON]}
                  alt={menu.name}
                />
              }
              onClick={() => handleMenuOnClik(menu.url)}
            >
              {menu.name}
            </Menu.Item>
          );
        }
      })}
    </Menu>
  );
};

export default Sidebar;
