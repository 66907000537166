import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';

import type { Moment } from 'moment';

import {
  Card,
  Row,
  Col,
  Space,
  Typography,
  Input,
  Button,
  message,
  DatePicker,
  TimePicker,
  Select,
} from 'antd';

import CustomTitle from '@components/Title';

import { ACARA } from '@constants/urls';

import fetchRequest from '@utils/fetcher';
import ReactQuill from 'react-quill';

const { Title } = Typography;
const { TextArea } = Input;

const TYPES = [
  {
    key: 'webinar',
    name: 'Webinar',
  },
  {
    key: 'study-consultation',
    name: 'Kajian & Konsultasi',
  },
  {
    key: 'training',
    name: 'Pelatihan',
  },
];

const UpsertEvent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [date, setDate] = useState<Moment>(moment());
  const [time, setTime] = useState<Moment>(moment());
  const [link, setLink] = useState('');
  const [type, setType] = useState('');
  const [id, setId] = useState('');

  const handleSubmit = async () => {
    const method = id ? 'PATCH' : 'POST';

    const param = {
      name,
      type,
      content: desc,
      date: moment(date).format('YYYY-MM-DD').toString(),
      time: moment(time).format('HH:mm').toString(),
      link,
    };

    try {
      await fetchRequest({
        method: method,
        path: 'event',
        data: id ? { ...param, id } : param,
      }).then((response) => {
        if (!response.error) {
          navigate(ACARA);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getCurrentData = async (id: string) => {
    try {
      await fetchRequest({ method: 'GET', path: 'event/id?id=' + id }).then(
        (response) => {
          if (!response.error) {
            setName(response.name || '');
            setDesc(response.content || '');
            setDate(response.date || '');
            setLink(response.link || '');
            setType(response.type || '');
            setTime(response.time || '');
          }
        }
      );
    } catch (error) {}
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const eventId = path[path.indexOf('edit') + 1];

      setId(eventId);

      getCurrentData(eventId);
    }
  }, []);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle name="Acara" url={ACARA} />
            <Title level={5}>Nama Acara</Title>
            <TextArea
              value={name}
              onChange={(e) => setName(e.target.value)}
              rows={2}
            />
            <Title level={5}>Tipe Acara</Title>
            <Select
              defaultValue={type ? type : ''}
              placeholder="Pilih tipe acara"
              onChange={(value) => setType(value)}
              style={{ width: '100%' }}
              value={type}
            >
              {TYPES.map((tp) => (
                <Select.Option key={tp.key} value={tp.key}>
                  {tp.name}
                </Select.Option>
              ))}
            </Select>
            <Title level={5}>Deskripsi Acara</Title>
            <ReactQuill
              value={desc}
              onChange={(value) => setDesc(value)}
            />
            <Title level={5}>Tanggal Acara</Title>
            <DatePicker
              defaultValue={date ? moment(date, 'YYYY-MM-DD') : undefined}
              placeholder="Pilih tanggal acara"
              onChange={(_, dateString) =>
                setDate(moment(dateString, 'YYYY-MM-DD'))
              }
              style={{ width: '100%' }}
              format={'YYYY-MM-DD'}
              value={moment(date, 'YYYY-MM-DD')}
            />
            <Title level={5}>Waktu Acara</Title>
            <TimePicker
              defaultValue={time ? moment(time, 'HH:mm') : undefined}
              placeholder="Pilih waktu acara"
              format={'HH:mm'}
              style={{ width: '100%' }}
              value={moment(time, 'HH:mm')}
              onChange={(_, timeString) => setTime(moment(timeString, 'HH:mm'))}
            />
            <Title level={5}>Link Acara</Title>
            <Input value={link} onChange={(e) => setLink(e.target.value)} />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default UpsertEvent;
