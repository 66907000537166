import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
  Card,
  Row,
  Col,
  Space,
  Button,
  message,
  Input,
  Typography,
} from 'antd';

import CustomTitle from '@components/Title';

import { DASHBOARD } from '@constants/urls';

import fetchRequest from '@utils/fetcher';

const { TextArea } = Input;
const { Title } = Typography;

const UpsertTestimony = () => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [content, setContent] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async () => {
    const param = { title: name, position, content };

    try {
      await fetchRequest({
        method: 'POST',
        path: 'resource/homepage/testimoni',
        data: id ? { ...param, id } : param,
      }).then((response) => {
        if (!response.error) {
          navigate(DASHBOARD);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getContent = async (id: string) => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource?id=' + id,
      }).then((response) => {
        if (!response.error) {
          setName(response.title || '');
          setContent(response.content || '');
          setPosition(response.otherData?.position || '');
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const testimonyId = path[path.indexOf('edit') + 1];

      setId(testimonyId);

      getContent(testimonyId);
    }
  }, []);
  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle name={'Testimoni'} url={DASHBOARD} />
            <Title level={5}>Nama</Title>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
            <Title level={5}>Jabatan</Title>
            <Input value={position} onChange={(e) => setPosition(e.target.value)} />
            <Title level={5}>Konten Testimoni</Title>
            <TextArea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              rows={4}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default UpsertTestimony;
