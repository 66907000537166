const ADD = '/add';
const EDIT = '/edit';

export const DASHBOARD = '/';

const BANNER = '/banner';
const CAROUSEL = '/carousel';
const NEW_PRODUCT = '/newProduct';
const KEUNGGULAN_KAMI = '/strength';
const FOKUS_KAMI = '/focus';
const LOGIN_BANNER = '/loginBanner';

export const ADD_BANNER = BANNER + ADD;
export const ADD_CAROUSEL = CAROUSEL + ADD;
export const ADD_NEW_PRODUCT = NEW_PRODUCT + ADD;
export const ADD_KEUNGGULAN_KAMI = KEUNGGULAN_KAMI + ADD;
export const ADD_FOKUS_KAMI = FOKUS_KAMI + ADD;

export const EDIT_BANNER = BANNER + EDIT;
export const EDIT_CAROUSEL = CAROUSEL + EDIT;
export const EDIT_NEW_PRODUCT = NEW_PRODUCT + EDIT;
export const EDIT_KEUNGGULAN_KAMI = KEUNGGULAN_KAMI + EDIT;
export const EDIT_FOKUS_KAMI = FOKUS_KAMI + EDIT;
export const EDIT_LOGIN_BANNER = LOGIN_BANNER + EDIT;

const TESTIMONI = '/testimony';
export const ADD_TESTIMONI = TESTIMONI + ADD;
export const EDIT_TESTIMONI = TESTIMONI + EDIT;

// ----

const ADD_KATEGORI = '/category' + ADD;
const EDIT_KATEGORI = '/category' + EDIT;

// ----

const TENTANG_KAMI = '/aboutUs';

export const SEJARAH = TENTANG_KAMI + '/history';
export const EDIT_SEJARAH = SEJARAH + EDIT;
export const ADD_KATEGORI_SEJARAH = SEJARAH + ADD_KATEGORI;
export const EDIT_KATEGORI_SEJARAH = SEJARAH + EDIT_KATEGORI;
export const ADD_PENDIRI = '/founders' + ADD;
export const EDIT_PENDIRI = '/founders' + EDIT;

export const VISI_MISI = TENTANG_KAMI + '/visionMision';
export const EDIT_VISI = VISI_MISI + '/vision' + EDIT;
export const ADD_MISI = VISI_MISI + '/mission' + ADD;
export const EDIT_MISI = VISI_MISI + '/mission' + EDIT;
export const ADD_FILOSOFI_LOGO = VISI_MISI + '/phylosophy' + ADD;
export const EDIT_FILOSOFI_LOGO = VISI_MISI + '/phylosophy' + EDIT;
export const ADD_KATEGORI_VISI_MISI = VISI_MISI + ADD_KATEGORI;
export const EDIT_KATEGORI_VISI_MISI = VISI_MISI + EDIT_KATEGORI;

export const MANAJEMEN = TENTANG_KAMI + '/management';
export const EDIT_MANAJEMEN = MANAJEMEN + EDIT;

const EDIT_DESKRIPSI = '/description' + EDIT;

export const ALF = TENTANG_KAMI + '/alf';
export const EDIT_DESKRIPSI_ALF = ALF + EDIT_DESKRIPSI;
const JAJARAN_AHLI = '/expert';
export const ADD_JAJARAN_AHLI = ALF + JAJARAN_AHLI + ADD;
export const EDIT_JAJARAN_AHLI = ALF + JAJARAN_AHLI + EDIT;
export const ADD_KATEGORI_ALF = ALF + ADD_KATEGORI;
export const EDIT_KATEGORI_ALF = ALF + EDIT_KATEGORI;

export const MEMBERSHIP = TENTANG_KAMI + '/membership';
export const EDIT_DESKRIPSI_MEMBERSHIP = MEMBERSHIP + EDIT_DESKRIPSI;
export const EDIT_KEUNTUNGAN = MEMBERSHIP + '/advantage' + EDIT;
export const ADD_KATEGORI_MEMBERSHIP = MEMBERSHIP + ADD_KATEGORI;
export const EDIT_KATEGORI_MEMBERSHIP = MEMBERSHIP + EDIT_KATEGORI;

// ----

const PRODUK_KAMI = '/product';
const EDIT_KATALOG = '/catalog' + EDIT;

export const KAJIAN_DAN_KONSULTASI = PRODUK_KAMI + '/research';

export const EDIT_KATALOG_KAJIAN_DAN_KONSULTASI =
  KAJIAN_DAN_KONSULTASI + '/publicConsultation' + EDIT_KATALOG;
export const EDIT_KATALOG_KONSULTASI_PUBLIK =
  KAJIAN_DAN_KONSULTASI + '/publicConsultation' + EDIT_KATALOG;
export const EDIT_KATALOG_KONSULTASI_KORPORASI =
  KAJIAN_DAN_KONSULTASI + '/corporationConsultation' + EDIT_KATALOG;

export const EDIT_GAMBAR_KONSULTASI =
  KAJIAN_DAN_KONSULTASI + '/mainPictureConsultation' + EDIT;
  export const EDIT_GAMBAR_KATALOG =
  KAJIAN_DAN_KONSULTASI + '/mainPictureCatalog' + EDIT;
  
export const EDIT_DESKRIPSI_KONSULTASI =
  KAJIAN_DAN_KONSULTASI + '/mainDesc' + EDIT;
export const EDIT_KENAPA_KAMI_DESC_KONSULTASI =
  KAJIAN_DAN_KONSULTASI + '/whyUsDesc' + EDIT;
export const EDIT_PENDEKATAN_DESC_KONSULTASI =
  KAJIAN_DAN_KONSULTASI + '/approachDesc' + EDIT;
export const EDIT_PENDEKETAN_ITEM_KONSULTASI =
  KAJIAN_DAN_KONSULTASI + '/approachItemDesc' + EDIT;

export const EDIT_STRATEGIC_BENEFIT =
  KAJIAN_DAN_KONSULTASI + 'strategicBenefit' + EDIT;
export const EDIT_STRATEGIC_DESC =
  KAJIAN_DAN_KONSULTASI + 'strategicDesc' + EDIT;
export const EDIT_STRATEGIC_ITEM =
  KAJIAN_DAN_KONSULTASI + 'strategicItem' + EDIT;
export const EDIT_ADVISORY_DESC = KAJIAN_DAN_KONSULTASI + 'advisoryDesc' + EDIT;
export const EDIT_ADVISORY_BENEFIT =
  KAJIAN_DAN_KONSULTASI + 'advisoryBenefit' + EDIT;
export const EDIT_ADVISORY_ITEM = KAJIAN_DAN_KONSULTASI + 'advisoryItem' + EDIT;

export const ADD_KATEGORI_KAJIAN_DAN_KONSULTASI =
  KAJIAN_DAN_KONSULTASI + ADD_KATEGORI;
export const EDIT_KATEGORI_KAJIAN_DAN_KONSULTASI =
  KAJIAN_DAN_KONSULTASI + EDIT_KATEGORI;

export const PELATIHAN = PRODUK_KAMI + '/learning';
export const EDIT_KATALOG_PELATIHAN = PELATIHAN + EDIT_KATALOG;
export const EDIT_LAYANAN_PELATIHAN = PELATIHAN + '/service' + EDIT;
export const ADD_KATEGORI_PELATIHAN = PELATIHAN + ADD_KATEGORI;
export const EDIT_KATEGORI_PELATIHAN = PELATIHAN + EDIT_KATEGORI;

export const EDIT_PELATIHAN_DESC = PELATIHAN + '/learningDesc' + EDIT;
export const EDIT_GAMBAR_PELATIHAN = PELATIHAN + '/learningPict' + EDIT;
export const EDIT_METODE_PELATIHAN = PELATIHAN + '/learningMethods' + EDIT;

export const WEBINAR = PRODUK_KAMI + '/webinar';
export const ADD_WEBINAR = WEBINAR + ADD;
export const EDIT_WEBINAR = WEBINAR + EDIT;
export const EDIT_DESC_WEBINAR = WEBINAR + '/description' + EDIT;
export const ADD_KATEGORI_WEBINAR = WEBINAR + ADD_KATEGORI;
export const EDIT_KATEGORI_WEBINAR = WEBINAR + EDIT_KATEGORI;

// ----

const LAYANAN = '/service';

export const ARTIKEL = LAYANAN + '/article';
export const ADD_ARTIKEL = ARTIKEL + ADD;
export const EDIT_ARTIKEL = ARTIKEL + EDIT;
export const ADD_KATEGORI_ARTIKEL = ARTIKEL + ADD_KATEGORI;
export const EDIT_KATEGORI_ARTIKEL = ARTIKEL + EDIT_KATEGORI;

export const BERITA = LAYANAN + '/news';
export const ADD_BERITA = BERITA + ADD;
export const EDIT_BERITA = BERITA + EDIT;
export const ADD_KATEGORI_BERITA = BERITA + ADD_KATEGORI;
export const EDIT_KATEGORI_BERITA = BERITA + EDIT_KATEGORI;

export const PUBLIKASI = LAYANAN + '/publication';
export const ADD_PUBLIKASI = PUBLIKASI + ADD;
export const EDIT_PUBLIKASI = PUBLIKASI + EDIT;
export const ADD_KATEGORI_PUBLIKASI = PUBLIKASI + ADD_KATEGORI;
export const EDIT_KATEGORI_PUBLIKASI = PUBLIKASI + EDIT_KATEGORI;

export const ACARA = LAYANAN + '/event';
export const ADD_ACARA = ACARA + ADD;
export const EDIT_ACARA = ACARA + EDIT;

export const GALERI = LAYANAN + '/gallery';
export const ADD_GALERI = GALERI + ADD;
export const EDIT_GALERI = GALERI + EDIT;
export const ADD_KATEGORI_GALERI = GALERI + ADD_KATEGORI;
export const EDIT_KATEGORI_GALERI = GALERI + EDIT_KATEGORI;

// ----

export const KONTAK = '/contactUs';
export const EDIT_KONTAK = KONTAK + EDIT;

// ----

export const AUTH = '/auth';
export const CHANGE_PASSWORD = '/changePassword'

export const PENGATURAN = '/setting';

// ----

const MEMBER = '/member';

export const MANAJEMEN_MEMBER = MEMBER + '/management';
export const EDIT_MANAJEMEN_MEMBER_AKTIF = MANAJEMEN_MEMBER + '/active' + EDIT;

export const DAFTAR_MEMBER_BARU = MEMBER + '/request';

// ----

export const NEWSLETTER = '/newsletter';

export const EDIT_HEADER_FOOTER = NEWSLETTER + EDIT;

export const ADD_NEWSLETTER = NEWSLETTER + ADD;
