import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, message } from 'antd';

import CategorySection from '@components/CategorySection';

import {
  ADD_KATEGORI_SEJARAH,
  ADD_PENDIRI,
  EDIT_KATEGORI_SEJARAH,
  EDIT_PENDIRI,
  EDIT_SEJARAH,
} from '@constants/urls';

import type { FoundersProps, HistoryProps } from '@customTypes/aboutUs';
import type { QuickLinkProps } from '@customTypes/quickLink';

import fetchRequest from '@utils/fetcher';
import PreviewImage from '@components/PreviewImage';

const { Title } = Typography;

const History = () => {
  const [historyData, setHistoryData] = useState<HistoryProps | {}>({});
  const [foundersData, setFoundersData] = useState<FoundersProps[]>([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const navigate = useNavigate();

  const historyColumns = [
    {
      title: 'Deskripsi',
      dataIndex: 'content',
      key: 'content',
      render: (_: string, record: any) => <div dangerouslySetInnerHTML={{__html: record.content}}></div>
    },
    {
      title: 'Aksi',
      key: 'action',
      render: () => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_SEJARAH)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];

  const foundersColumns = [
    {
      title: 'Foto',
      dataIndex: 'url',
      render: (_: string, record: any) => (
        <PreviewImage
          key="key"
          buttonName="Lihat Icon"
          mediaUrl={record.url}
        ></PreviewImage>
      ),
    },
    {
      title: 'Nama',
      dataIndex: 'content',
    },
    {
      title: 'Jabatan',
      dataIndex: 'title',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_PENDIRI + '/' + record.id)}
          >
            Ubah
          </Button>
        </Space>
      ),
    },
  ];

  const getHistoryData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/about-us/history',
      }).then((response) => {
        if (!response.error) {
          setHistoryData(response.history || {});
          setFoundersData(response.founders || {});
          setQuickLinkList(response.quickLinks || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getHistoryData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title={<Title level={4}>Sejarah</Title>}>
        <Table
          columns={historyColumns}
          dataSource={[historyData]}
          pagination={false}
        />
      </Card>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>Pendiri</Title>
            <Button onClick={() => navigate(ADD_PENDIRI)}>Tambah Baru</Button>
          </Space>
        }
      >
        <Table
          columns={foundersColumns}
          dataSource={foundersData}
          pagination={false}
        />
      </Card>
      <CategorySection
        data={quickLinkList
          .filter((ql) => ql.pageName === 'History')
          .map((ql, i) => ({ ...ql, key: i + 1 }))}
        addLinkUrl={ADD_KATEGORI_SEJARAH}
        editLinkUrl={EDIT_KATEGORI_SEJARAH}
        onRefetch={getHistoryData}
      />
    </Space>
  );
};

export default History;
