import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import {
  Input,
  Row,
  Col,
  Card,
  Space,
  Typography,
  Button,
  message,
  Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import CustomTitle from '@components/Title';

import { SEJARAH } from '@constants/urls';

import fetchRequest, { fetchFormData } from '@utils/fetcher';
import ReactQuill from 'react-quill';

const { TextArea } = Input;
const { Title } = Typography;

const EditHistory = () => {
  const [content, setContent] = useState('');
  const [header, setHeader] = useState<any[]>([]);
  const navigate = useNavigate();

  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setHeader(fileList);
  };

  const getHistoryData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/about-us/history',
      }).then((response) => {
        if (!response.error) {
          const historyData = response.history || {};
          setContent(historyData.content || '');
          if (historyData.url) {
            setHeader([
              {
                uid: '-1',
                name: 'sejarah.png',
                status: 'done',
                url: historyData.url,
              },
            ]);
          }
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('content', content);
    formData.append('title', 'Sejarah');

    if (header) {
      formData.append('file', header[0]?.originFileObj || header[0]?.url);
    }

    try {
      await fetchFormData({
        method: 'POST',
        path: 'resource/about-us/history/desc',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(SEJARAH);
        } else {
          if (Array.isArray(response.message)) {
            response.message.map((msg: string) => message.error(msg));
          } else {
            message.error(response.message);
          }
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getHistoryData();
  }, []);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle
              name="Sejarah"
              url={SEJARAH}
              toolTip="Menerima jenis file .jpg, .jpeg, dan .png. Total maksimal file yang dapat diunggah adalah 25mb."
            />
            <Upload
              listType="picture"
              maxCount={1}
              accept=".png, .jpg, .jpeg"
              fileList={header}
              onChange={handleImageUpload}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Title level={5}>Konten Sejarah</Title>
            <ReactQuill
              value={content}
              onChange={(value) => setContent(value)}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditHistory;
