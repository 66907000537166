import { useEffect, useState } from 'react';

import {
  Input,
  Row,
  Col,
  Card,
  Space,
  Typography,
  Button,
  message,
} from 'antd';

import 'react-quill/dist/quill.snow.css';

import CustomTitle from '@components/Title';

import { KAJIAN_DAN_KONSULTASI } from '@constants/urls';
import fetchRequest from '@utils/fetcher';
import { useNavigate } from 'react-router';

const { TextArea } = Input;
const { Title } = Typography;

const EditApproachItem = () => {
  const [id, setId] = useState('');
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const param = {
      content,
      title,
    };

    try {
      await fetchRequest({
        method: 'POST',
        path: 'resource/service/study-and-consul/method-item',
        data: id ? { ...param, id } : param,
      }).then((response) => {
        if (!response.error) {
          navigate(KAJIAN_DAN_KONSULTASI);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const getConsultationData = async (id: string) => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource?id=' + id,
      }).then((response) => {
        if (!response.error) {
          setContent(response.content || '');
          setTitle(response.title || '');
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const benefitId = path[path.indexOf('edit') + 1];

      setId(benefitId);

      getConsultationData(benefitId);
    }
  }, []);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space align="baseline">
              <CustomTitle
                name="Metode Pendekatan"
                url={KAJIAN_DAN_KONSULTASI}
              />
            </Space>
            <Title level={5}>Judul</Title>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
            <Title level={5}>Deskripsi</Title>
            <TextArea
              rows={4}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditApproachItem;
