import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Card, Typography, Button, Space, Table, message } from 'antd';

import { NewsletterProps } from '@customTypes/newsletter';

import fetchRequest from '@utils/fetcher';
import { EDIT_HEADER_FOOTER } from '@constants/urls';

const { Title, Text } = Typography;

const HeaderFooterCard = () => {
  const navigate = useNavigate();

  const [header, setHeader] = useState<NewsletterProps[]>([]);
  const [footer, setFooter] = useState<NewsletterProps[]>([]);

  const getData = async (type: 'Header' | 'Footer') => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'newsletter?types=' + type,
      }).then((response) => {
        if (!response.error) {
          switch (type) {
            case 'Header':
              setHeader(response || []);
              break;
            case 'Footer':
              setFooter(response || []);
              break;
            default:
              break;
          }
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getData('Header');
    getData('Footer');
  }, []);

  const columns = [
    {
      title: 'Kategori',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => (
        <Text type="secondary">{text.toUpperCase()}</Text>
      ),
    },
    {
      title: 'Tanggal Terakhir Diperbaharui',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => <Text>{dayjs(text).format('YYYY-MM-DD')}</Text>,
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: NewsletterProps) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_HEADER_FOOTER + '/' + record.id)}
          >
            Ubah
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={
        <Space align="end">
          <Title level={4} style={{ marginBottom: 0 }}>
            {`Header & Footer`}
          </Title>
        </Space>
      }
    >
      <Table
        columns={columns}
        dataSource={[...header, ...footer]}
        pagination={false}
      />
    </Card>
  );
};

export default HeaderFooterCard;
