import { useNavigate } from 'react-router';

import { Space, Card, Table, Button, Typography, Tag, message } from 'antd';

import DeleteButton from '@components/DeleteButton';

import { EDIT_MANAJEMEN_MEMBER_AKTIF } from '@constants/urls';

import { MemberType } from '@customTypes/member';
import fetchRequest from '@utils/fetcher';

const { Title } = Typography;

interface Props {
  data: MemberType[];
  onRefresh: () => void;
}

const ActiveMemberCard = (props: Props) => {
  const { data, onRefresh } = props;

  const navigate = useNavigate();

  const handleDelete = async (email: string) => {
    try {
      await fetchRequest({
        method: 'DELETE',
        path: 'user-member?email=' + email,
      }).then((response) => {
        if (!response.error) {
          onRefresh();
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: 'Nama',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Nomor Telepon',
      key: 'phoneNumber',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'Role',
      key: 'memberRole',
      dataIndex: 'memberRole',
      render: (text: string) => (
        <Tag color={text === 'MEMBER' ? 'red' : 'blue'}>{text}</Tag>
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() =>
              navigate(EDIT_MANAJEMEN_MEMBER_AKTIF + '/' + record.id)
            }
          >
            Ubah
          </Button>
          <DeleteButton onDelete={() => handleDelete(record.email)} />
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Member Aktif
            </Title>
          </Space>
        }
        style={{ overflowX: 'scroll' }}
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultCurrent: 1,
            total: data.length,
            pageSize: 5,
          }}
        />
      </Card>
    </Space>
  );
};

export default ActiveMemberCard;
