import { useState } from 'react';

import { Space, Card, Table, Button, Typography, Tag, message } from 'antd';

import { MemberRequestType } from '@customTypes/member';
import fetchRequest from '@utils/fetcher';
import RequestModal from './RequestModal';

const { Title } = Typography;

interface Props {
  data: MemberRequestType[];
  onRefresh: () => void;
  type: 'REGISTRATION' | 'EXTEND';
}

const ReqeustMemberCard = (props: Props) => {
  const { data, onRefresh, type } = props;
  const [isModalOpen, setIsModalOpen] = useState<Record<string, boolean>>({});

  const handleModal = (id: string) => {
    const currentModal = isModalOpen[id];

    if (typeof currentModal === 'undefined') {
      setIsModalOpen({ [id]: true });
    } else {
      setIsModalOpen({ [id]: !currentModal });
    }
  };

  const handleClickButton = async (
    email: string,
    isAccepted: boolean = true,
    expiredYear: number = 0
  ) => {
    let param = '?approved=' + isAccepted + '&email=' + email;

    if (expiredYear > 0) {
      param += '&extendYear=' + expiredYear;
    }

    try {
      await fetchRequest({
        method: 'POST',
        path: 'user-member/membership-request' + param,
      }).then((response) => {
        if (!response.error) {
          if (isAccepted) {
            handleModal(email);
          }
          onRefresh();
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      width: '90%',
    },
    {
      title: 'Aksi',
      key: 'action',
      width: '10%',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => handleModal(record.email)}
          >
            Terima
          </Button>
          <Button
            type="link"
            className="button-link-red"
            onClick={() => handleClickButton(record.email, false)}
          >
            Tolak
          </Button>
          <RequestModal
            isOpen={isModalOpen[record.email]}
            onClose={() => handleModal(record.email)}
            onSubmit={(year) => handleClickButton(record.email, true, year)}
          />
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              {type === 'EXTEND' ? 'Pengajuan Perpanjangan' : 'Member Baru'}
            </Title>
          </Space>
        }
        style={{ overflowX: 'scroll' }}
      >
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            defaultCurrent: 1,
            total: data.length,
            pageSize: 5,
          }}
          style={{ width: '100%' }}
        />
      </Card>
    </Space>
  );
};

export default ReqeustMemberCard;
