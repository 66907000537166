import { useEffect, useState, useCallback } from 'react';

import {
  Input,
  Row,
  Col,
  Card,
  Space,
  Typography,
  Button,
  Tooltip,
  Avatar,
  Upload,
  message,
  Select,
} from 'antd';

import { InfoOutlined, UploadOutlined } from '@ant-design/icons';

import CustomTitle from '@components/Title';

import type { NormalProps } from '@customTypes/aboutUs';

import { VISI_MISI } from '@constants/urls';
import fetchRequest, { fetchFormData } from '@utils/fetcher';
import { useNavigate } from 'react-router';

const { TextArea } = Input;
const { Title } = Typography;

const EditPhilosophy = () => {
  const [id, setId] = useState('');
  const [title, setTitle] = useState('-');
  const [content, setContent] = useState('');
  const [mediaUrl, setMediaUrl] = useState<any[]>([]);
  const [url, setUrl] = useState('');
  const [contentType, setContentType] = useState('');
  const [data, setData] = useState<NormalProps>();

  const navigate = useNavigate();

  const getApiUrl = useCallback((subType: string) => {
    switch (subType) {
      case 'LogoColorPhilosophyDetailDescription':
        setUrl('resource/about-us/color-philosophy/detail-desc');
        setContentType('text');
        break;
      case 'LogoSymbolPhilosophyDetailDescription':
        setUrl('resource/about-us/symbol-philosophy/detail-desc');
        setContentType('text');
        break;
      case 'LogoColorPhilosophyImage':
        setUrl('resource/about-us/color-philosophy/image');
        setContentType('image');
        break;
      case 'LogoColorPhilosophyDescription':
        setUrl('resource/about-us/color-philosophy/desc');
        setContentType('text');
        break;
      case 'LogoSymbolPhilosophyImage':
        setUrl('resource/about-us/symbol-philosophy/image');
        setContentType('image');
        break;
      case 'LogoSymbolPhilosophyDescription':
        setUrl('resource/about-us/symbol-philosophy/desc');
        setContentType('text');
        break;
      default:
        setContentType('text');
        return;
    }
  }, []);

  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setMediaUrl(fileList);
  };

  const handleSubmit = async () => {
    if (contentType === 'image') {
      const formData = new FormData();

      if (mediaUrl) {
        formData.append('file', mediaUrl[0]?.originFileObj || mediaUrl[0]?.url);
      }
      if (id) {
        formData.append('id', id);
      }

      try {
        await fetchFormData({
          method: 'POST',
          path: url,
          data: formData,
        }).then((response) => {
          if (!response.error) {
            navigate(VISI_MISI);
          } else {
            message.error(response.error.message);
          }
        });
      } catch (error) {
        message.error(error.message);
      }
    } else {
      let dataToPost: Record<string, string> = { content };

      if (
        url === 'resource/about-us/color-philosophy/detail-desc' ||
        url === 'resource/about-us/symbol-philosophy/detail-desc'
      ) {
        dataToPost = {
          content,
          title,
        };
        if (id) dataToPost.id = id;
      }

      try {
        await fetchRequest({
          method: 'POST',
          path: url,
          data: dataToPost,
        }).then((response) => {
          if (!response.error) {
            navigate(VISI_MISI);
          } else {
            message.error(response.error.message);
          }
        });
      } catch (error: any) {
        message.error(error.message);
      }
    }
  };

  const getPhilosophyData = async (id: string) => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource?id=' + id,
      }).then((response) => {
        getApiUrl(response.subType);
        setContent(response.content);
        setTitle(response.title);
        if (response.url) {
          setMediaUrl([
            {
              uid: '-1',
              name: title + '.png',
              status: 'done',
              url: response.url,
            },
          ]);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const philosophyId = path[path.indexOf('edit') + 1];

      setId(philosophyId);
      getPhilosophyData(philosophyId);
    }
  }, []);

  if (contentType === 'image') {
    return (
      <Row>
        <Col span={12} offset={6}>
          <Card>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Space align="baseline">
                <CustomTitle name="Filosofi Logo - Gambar" url={VISI_MISI} />
                <Tooltip title="Menerima jenis file: .jpg, .jpeg, .png. Untuk icon transparan gunakan .png">
                  <Avatar
                    size={'small'}
                    icon={<InfoOutlined />}
                    style={{ backgroundColor: '#8C8C8C' }}
                  ></Avatar>
                </Tooltip>
              </Space>
              <Title level={5}>Gambar</Title>
              <Upload
                listType="picture"
                maxCount={1}
                accept=".png, .jpg, .jpeg"
                fileList={mediaUrl}
                onChange={handleImageUpload}
                beforeUpload={()=> false}
              >
                <Button icon={<UploadOutlined />}>Unggah Gambar</Button>
              </Upload>
              <Row justify="end">
                <Col>
                  <Button type="primary" danger onClick={() => handleSubmit()}>
                    Simpan Perubahan
                  </Button>
                </Col>
              </Row>
            </Space>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle name="Filosofi Logo - Deskripsi" url={VISI_MISI} />
            {!id && (
              <Select
                onChange={(value) => setUrl(value)}
                style={{ width: '100%' }}
              >
                <Select.Option value="resource/about-us/color-philosophy/detail-desc">
                  Filosofi Warna
                </Select.Option>
                <Select.Option value="resource/about-us/symbol-philosophy/detail-desc">
                  Filosofi Simbol
                </Select.Option>
              </Select>
            )}
            <Title level={5}>Deskripsi</Title>
            <TextArea
              rows={4}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            {(url === 'resource/about-us/color-philosophy/detail-desc' ||
              url === 'resource/about-us/symbol-philosophy/detail-desc' ||
              !id) && (
              <>
                <Title level={5}>Warna Background</Title>
                <TextArea
                  rows={4}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </>
            )}
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditPhilosophy;
