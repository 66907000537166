import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, message } from 'antd';

import CategorySection from '@components/CategorySection';

import {
  ADD_KATEGORI_MEMBERSHIP,
  EDIT_DESKRIPSI_MEMBERSHIP,
  EDIT_KATEGORI_MEMBERSHIP,
  EDIT_KEUNTUNGAN,
} from '@constants/urls';

import type { MembershipProps } from '@customTypes/aboutUs';
import type { QuickLinkProps } from '@customTypes/quickLink';

import fetchRequest from '@utils/fetcher';

const { Title } = Typography;

const Membership = () => {
  const [contentData, setContentData] = useState<MembershipProps | {}>({});
  const [benefitData, setBenefitData] = useState<MembershipProps | {}>({});
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const navigate = useNavigate();

  const contentColumns = [
    {
      title: 'Konten',
      dataIndex: 'content',
      key: 'content',
      render: (_: string, record: any) => <div dangerouslySetInnerHTML={{__html: record.content}}></div>,
    },
    {
      title: 'Aksi',
      key: 'action',
      render: () => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_DESKRIPSI_MEMBERSHIP)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];

  const benefitColumns = [
    {
      title: 'Konten',
      dataIndex: 'content',
      key: 'content',
      render: (_: string, record: any) => <div dangerouslySetInnerHTML={{__html: record.content}}></div>,
    },
    {
      title: 'Aksi',
      key: 'action',
      render: () => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_KEUNTUNGAN)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];

  const getMembershipData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/about-us/membership',
      }).then((response) => {
        if (!response.error) {
          setContentData(response.description || {});
          setBenefitData(response.benefit || {});
          setQuickLinkList(response.quickLinks || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getMembershipData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title={<Title level={4}>Membership</Title>}>
        <Table
          columns= {contentColumns}
          dataSource={[contentData]}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Keuntungan</Title>}>
        <Table
          columns={benefitColumns}
          dataSource={[benefitData]}
          pagination={false}
        />
      </Card>
      <CategorySection
        data={quickLinkList
          .filter((ql) => ql.pageName === 'Membership')
          .map((ql, i) => ({ ...ql, key: i + 1 }))}
        addLinkUrl={ADD_KATEGORI_MEMBERSHIP}
        editLinkUrl={EDIT_KATEGORI_MEMBERSHIP}
        onRefetch={getMembershipData}
      />
    </Space>
  );
};

export default Membership;
