import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import GeneralRoutes from './generalRoutes';
import AuthRoutes from './authRoutes';

import { DASHBOARD, AUTH } from '@constants/urls';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path={DASHBOARD + '*'} element={<GeneralRoutes />} />
        <Route path={AUTH} element={<AuthRoutes />} />
      </Routes>
    </Router>
  );
};

export default App;
