import { useState } from 'react';
import { useNavigate } from 'react-router';

import ReactQuill from 'react-quill';

import {
  Card,
  Row,
  Col,
  Space,
  Button,
  message,
  Typography,
  Input,
} from 'antd';

import CustomTitle from '@components/Title';

import { NEWSLETTER } from '@constants/urls';

import 'react-quill/dist/quill.snow.css';
import { fetchFormData } from '@utils/fetcher';

const { Title } = Typography;

const CreateNewsletter = () => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append('title', title);
    formData.append('type', 'Content');
    formData.append('content', content);

    try {
      await fetchFormData({
        method: 'POST',
        path: 'newsletter',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(NEWSLETTER);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle name="Detail Newsletter" url={NEWSLETTER} />
            <Title level={5}>Judul</Title>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
            <Title level={5}>Konten</Title>
            <ReactQuill
              value={content}
              onChange={(value) => setContent(value)}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default CreateNewsletter;
