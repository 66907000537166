import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import CryptoJS from 'crypto-js';

import {
  Card,
  Row,
  Col,
  Typography,
  Input,
  Button,
  Space,
  message,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { useGlobalState } from '@contexts/globalState';

import { fetchAuthRequest } from '@utils/fetcher';

import './styles.css';
import { DASHBOARD } from '@constants/urls';

const { Text, Title } = Typography;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const cookies = new Cookies();

  const { setLoginInfo } = useGlobalState();

  const handleLogin = async () => {
    const param = {
      email,
      password,
      userGroup: 'ADMIN',
    };

    try {
      await fetchAuthRequest({
        method: 'POST',
        path: 'auth/authenticate',
        data: param,
      }).then((response) => {
        if (response.authData.refreshToken) {
          const exp = response.authData.accessToken.payload.exp;

          const encryptedEmail = CryptoJS.AES.encrypt(
            email,
            'user-email'
          ).toString();

          setLoginInfo({
            isLogin: true,
            username: response.userData.name,
            email,
            refreshToken: response.authData.refreshToken.token,
            expireAt: new Date(exp * 1000),
          });
          cookies.set('admin-token', response.authData.accessToken.jwtToken, {
            path: '/',
            expires: new Date(exp * 1000),
          });
          cookies.set('admin-r-token', response.authData.refreshToken.token, {
            path: '/',
          });
          cookies.set('AE', encryptedEmail, {
            path: '/',
          });

          navigate(DASHBOARD);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <Row>
      <Col span={12} offset={6} className="styLoginCol">
        <Card className="styLoginCard">
          <Row>
            <Col span={12} className="styLeftSideLogin">
              <Space
                align="start"
                direction="vertical"
                size="large"
                className="stySpace"
              >
                <Title level={3}>Selamat Datang, Admin</Title>
                <div>
                  <Title level={5}>Email</Title>
                  <Input
                    placeholder="Masukkan Email"
                    prefix={<UserOutlined />}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <Title level={5}>Password</Title>
                  <Input.Password
                    placeholder="Masukkan Password"
                    prefix={<LockOutlined />}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <Button
                  danger
                  type="primary"
                  style={{ width: '100%' }}
                  onClick={() => handleLogin()}
                >
                  Masuk
                </Button>
              </Space>
            </Col>
            <Col span={12} className="styRightSideLogin"></Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
