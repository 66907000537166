import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import {
  Input,
  Row,
  Col,
  Card,
  Space,
  Typography,
  Button,
  message,
} from 'antd';

import CustomTitle from '@components/Title';

import { MEMBERSHIP } from '@constants/urls';

import fetchRequest from '@utils/fetcher';
import ReactQuill from 'react-quill';

const { Title } = Typography;

const EditBenefit = () => {
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  const getBenefitData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/about-us/membership',
      }).then((response) => {
        if (!response.error) {
          const benefitData = response.benefit || {};
          setContent(benefitData.content || '');
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleSubmit = async () => {
    try {
      await fetchRequest({
        method: 'POST',
        path: 'resource/about-us/membership/benefit',
        data: { content },
      }).then((response) => {
        if (!response.error) {
          navigate(MEMBERSHIP);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getBenefitData();
  }, []);

  return (
    <Row>
      <Col span={16} offset={4}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle name="Membership" url={MEMBERSHIP} />
            <Title level={5}>Konten Keuntungan</Title>
            <ReactQuill
              value={content}
              onChange={(value) => setContent(value)}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditBenefit;
