import { useNavigate } from 'react-router-dom';

import { Card, Typography, Button, Space, Table, Popover, message } from 'antd';

import type { QuickLinkProps } from '@customTypes/quickLink';

import DeleteButton from '../DeleteButton';
import fetchRequest from '@utils/fetcher';

interface CategorySectionProps {
  data: QuickLinkProps[];
  addLinkUrl: string;
  editLinkUrl: string;
  onRefetch: () => void;
}

const { Title } = Typography;

const CategorySection = (props: CategorySectionProps) => {
  const { data, addLinkUrl, editLinkUrl, onRefetch } = props;

  const navigate = useNavigate();

  const handleDelete = async (id: string) => {
    try {
      await fetchRequest({ method: 'DELETE', path: 'category?id=' + id }).then(
        (response) => {
          if (!response.error) {
            onRefetch();
          } else {
            message.error(response.error.message);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const categoryColumns = [
    {
      title: 'No.',
      dataIndex: 'key',
    },
    {
      title: 'Nama Kategori Tambahan',
      dataIndex: 'name',
    },
    {
      title: 'Tautan Kategori Tambahan',
      dataIndex: 'linkTo',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: QuickLinkProps) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(editLinkUrl + '/' + record.id)}
          >
            Ubah
          </Button>
          <DeleteButton onDelete={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={
        <Space align="end">
          <Title level={4} style={{ marginBottom: 0 }}>
            Kategori Tambahan
          </Title>
          <Button onClick={() => navigate(addLinkUrl)}>Tambah Baru</Button>
        </Space>
      }
    >
      <Table columns={categoryColumns} dataSource={data} pagination={false} />
    </Card>
  );
};

export default CategorySection;
