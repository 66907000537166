import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
  Card,
  Row,
  Col,
  Space,
  Button,
  message,
  Upload,
  Typography,
  Input,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import CustomTitle from '@components/Title';

import { DASHBOARD } from '@constants/urls';

import fetchRequest, { fetchFormData } from '@utils/fetcher';

const { Title } = Typography;
const { TextArea } = Input;

const EditStrength = () => {
  const [file, setFile] = useState<any[]>([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [id, setId] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFile(fileList);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    console.log(file);

    if (file) {
      formData.append('file', file[0]?.originFileObj || file[0]?.url);
    }
    formData.append('title', title);
    formData.append('content', content);

    if (id) {
      formData.append('id', id);
    }

     // Log FormData contents for debugging
 console.log('FormData contents:');
 formData.forEach((value, key) => {
   console.log(`${key}:`, value);
 });

    try {
      await fetchFormData({
        method: 'POST',
        path: 'resource/homepage/our-strength',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(DASHBOARD);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getContent = async (id: string) => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource?id=' + id,
      }).then((response) => {
        if (!response.error) {
          if (response.url) {
            setFile([
              {
                uid: '-1',
                name: 'banner.png',
                status: 'done',
                url: response.url,
              },
            ]);
            setTitle(response.title || '');
            setContent(response.content || '');
          }
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const strengthId = path[path.indexOf('edit') + 1];

      setId(strengthId);

      getContent(strengthId);
    }
  }, []);
  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle
              name="Keunggulan Kami"
              url={DASHBOARD}
              toolTip="Menerima jenis file .jpg, .jpeg, dan .png. Total maksimal file yang dapat diunggah adalah 25mb."
            />
            <Title level={5}>Icon</Title>
            <Upload
              listType="picture"
              maxCount={1}
              accept=".png, .jpg, .jpeg"
              fileList={file}
              onChange={handleImageUpload}
              beforeUpload={()=> false}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Title level={5}>Keunggulan</Title>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
            <Title level={5}>Deskripsi</Title>
            <TextArea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              rows={4}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditStrength;
