import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';

import {
  Card,
  Row,
  Col,
  Space,
  Button,
  message,
  Typography,
  Image,
  Input,
  Select,
  DatePicker,
  Upload,
  Form,
  Table,
  Checkbox,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import CustomTitle from '@components/Title';

import { MANAJEMEN_MEMBER } from '@constants/urls';

import { MemberType, CertificateType } from '@customTypes/member';
import { EventProps } from '@customTypes/info';

import fetchRequest, { fetchFormData } from '@utils/fetcher';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ROLE_LIST = [
  {
    name: 'Member',
    value: 'MEMBER',
  },
  {
    name: 'BoE',
    value: 'BOARD_OF_EXPERT',
  },
];

const MemberDetail = () => {
  const [memberData, setMemberData] = useState<MemberType>();
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  const [membershipStart, setMembershipStart] = useState('');
  const [membershipEnd, setMembershipEnd] = useState('');
  const [status, setStatus] = useState(false);
  const [existingCertif, setExistingCertif] = useState<CertificateType[]>([]);
  const [certifFile, setCertifFile] = useState<any[]>([]);
  const [eventData, setEventData] = useState<EventProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [subscribe, setSubscribe] = useState(false);

  const [formCertifName] = Form.useForm();

  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const certifList = Object.values(formCertifName.getFieldsValue(true)).map(
      (val) => {
        return {
          url: '',
          eventId: val,
        };
      }
    );

    const certificates = {
      items: [...existingCertif, ...certifList],
    };

    const membershipValidStart =
      moment(membershipStart, 'DD/MM/YYYY').unix() * 1000;
    const membershipValidEnd =
      moment(membershipEnd, 'DD/MM/YYYY').unix() * 1000;

    const formData = new FormData();

    formData.append('name', name);
    formData.append('email', email);
    formData.append('phoneNumber', phoneNumber);
    formData.append('isActive', String(status));
    formData.append('memberRole', role);
    formData.append('membershipValidStart', String(membershipValidStart));
    formData.append('membershipValidEnd', String(membershipValidEnd));
    formData.append('certificates', JSON.stringify(certificates));
    if (certifFile) {
      for (let index = 0; index < certifFile.length; index++) {
        const certif = certifFile[index];
        formData.append('certificateFiles', certif.originFileObj);
      }
    }
    formData.append('subscribeNewsletter', String(subscribe));

    try {
      await fetchFormData({
        method: 'PATCH',
        path: 'user-member',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(MANAJEMEN_MEMBER);
        } else {
          message.error(response.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getMemberData = async (id: string) => {
    try {
      await fetchRequest({ method: 'GET', path: 'user-member?id=' + id }).then(
        (response) => {
          if (!response.error) {
            setMemberData(response || {});
            setName(response.name || '');
            setEmail(response.email || '');
            setPhoneNumber(response.phoneNumber || '');
            setRole(response.memberRole || '');
            setSubscribe(response.subscribeNewsletter || false);
            if (response.membershipValidEnd) {
              const end = moment(response.membershipValidEnd).format(
                'DD/MM/YYYY'
              );
              setMembershipEnd(end);
            }
            if (response.membershipValidStart) {
              const start = moment(response.membershipValidStart).format(
                'DD/MM/YYYY'
              );
              setMembershipStart(start);
            }
            setStatus(response.isActive || false);
            setExistingCertif(response.certificates || []);
          } else {
            message.error(response.error.message);
          }
          setIsLoading(false);
        }
      );
    } catch (error: any) {
      message.error(error.message);

      setIsLoading(false);
    }
  };

  const getEventData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'event' }).then((response) => {
        if (!response.error) {
          setEventData(response || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handlePdfUpload = async (info: any) => {
    let fileList = [...info.fileList];

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setCertifFile(fileList);
  };

  const handleCertifName = (id: number, value: string) => {
    formCertifName.setFieldsValue({ [id]: value });
  };

  const handleDeleteCertif = async (eventId: string) => {
    try {
      await fetchRequest({
        method: 'DELETE',
        path: 'user-member/certificate?email=' + email + '&eventId=' + eventId,
      }).then((response) => {
        if (!response.error) {
          message.success('Sertifikat telah berhasil dihapus');
          getMemberData(id);
        } else {
          message.error(response.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getEventData();

    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const memberId = path[path.indexOf('edit') + 1];

      setId(memberId);

      getMemberData(memberId);
    }
  }, []);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle
              name="Detail Member"
              url={MANAJEMEN_MEMBER}
              toolTip="Unggah sertifikat dalam bentuk file .pdf maksimal 25mb."
            />
            <Title level={5}>Informasi Member</Title>
            <Row gutter={[8, 8]} align="bottom">
              <Col span={8}>
                <Image
                  width={100}
                  height={100}
                  src={
                    memberData?.photoUrl ||
                    'https://www.seekpng.com/png/detail/966-9665317_placeholder-image-person-jpg.png'
                  }
                  preview={false}
                  style={{ borderRadius: '100%' }}
                />
              </Col>
              <Col span={16}>
                <Space
                  align="start"
                  direction="vertical"
                  style={{ width: '100%' }}
                  className="stySpaceItem"
                >
                  <Title level={5} style={{ marginBottom: '0' }}>
                    Nama
                  </Title>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ width: '100%' }}
                  />
                </Space>
              </Col>
            </Row>
            <Title level={5} style={{ marginBottom: '0' }}>
              Email
            </Title>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: '100%' }}
            />
            <Title level={5} style={{ marginBottom: '0' }}>
              Telepon
            </Title>
            <Input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={{ width: '100%' }}
            />
            <Title level={5} style={{ marginBottom: '0' }}>
              Role
            </Title>
            <Select
              defaultValue={role}
              value={role}
              onChange={(value) => setRole(value)}
              style={{ width: '100%' }}
            >
              {ROLE_LIST.map((rl) => (
                <Option key={rl.value} value={rl.value}>
                  {rl.name}
                </Option>
              ))}
            </Select>
            <Title level={5} style={{ marginBottom: '0' }}>
              Waktu Berlaku Keanggotaan
            </Title>
            {!isLoading && (
              <RangePicker
                defaultValue={[
                  moment(membershipStart, 'DD/MM/YYYY'),
                  moment(membershipEnd, 'DD/MM/YYYY'),
                ]}
                format={'DD/MM/YYYY'}
                style={{ width: '100%' }}
                onChange={(_, dateString) => {
                  setMembershipStart(dateString[0]);
                  setMembershipEnd(dateString[1]);
                }}
              />
            )}
            <Title level={5} style={{ marginBottom: '0' }}>
              Status Keanggotaan
            </Title>
            <Select
              defaultValue={status}
              value={status}
              onChange={(value) => setStatus(value)}
              style={{ width: '100%' }}
            >
              <Option value={true}>Aktif</Option>
              <Option value={false}>Tidak Aktif</Option>
            </Select>
            <Title level={5} style={{ marginBottom: '0' }}>
              Sertifikat
            </Title>
            <Table
              pagination={false}
              columns={[
                {
                  title: 'Acara',
                  key: 'eventId',
                  dataIndex: 'eventId',
                  render: (text: string, record: Record<string, string>) => {
                    const eventName = eventData.filter((dt) => dt.id === text);
                    return eventName[0]?.name || '';
                  },
                },
                {
                  title: 'Aksi',
                  key: 'eventId',
                  dataIndex: 'eventId',
                  render: (text: string) => (
                    <Button danger onClick={() => handleDeleteCertif(text)}>
                      Hapus
                    </Button>
                  ),
                },
              ]}
              dataSource={existingCertif}
            />
            <Upload
              listType="text"
              accept=".pdf"
              fileList={certifFile}
              onChange={handlePdfUpload}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Form form={formCertifName}>
              {certifFile.map((_, i: number) => (
                <Form.Item>
                  <Select
                    onChange={(value) => handleCertifName(i, value)}
                    placeholder={`Pilih nama acara untuk sertifikat ke-${
                      i + 1
                    }`}
                    style={{ width: '100%' }}
                  >
                    {eventData.map((event) => (
                      <Option key={event.id} value={event.id}>
                        {event.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ))}
            </Form>
            <Checkbox
              onChange={(e) => setSubscribe(e.target.checked)}
              checked={subscribe}
            >
              Berlangganan Newsletter
            </Checkbox>
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default MemberDetail;
