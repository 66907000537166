import CategorySection from '@components/CategorySection';
import PreviewImage from '@components/PreviewImage';
import {
  ADD_FILOSOFI_LOGO,
  ADD_KATEGORI_VISI_MISI,
  ADD_MISI,
  EDIT_FILOSOFI_LOGO,
  EDIT_KATEGORI_VISI_MISI,
  EDIT_MISI,
  EDIT_VISI,
} from '@constants/urls';
import {
  VisionProps,
  MisionProps,
  PhilosophyProps,
  NormalProps,
} from '@customTypes/aboutUs';
import { QuickLinkProps } from '@customTypes/quickLink';
import fetchRequest from '@utils/fetcher';
import { Button, Space, Card, Table, Typography, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const VisionMision = () => {
  const navigate = useNavigate();
  const [visionData, setVisionData] = useState<VisionProps | {}>({});
  const [misionData, setMisionData] = useState<MisionProps[]>([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<{ [x: number]: boolean }>({});
  const [colorPhilosophyData, setColorPhilosophyData] =
    useState<PhilosophyProps>({
      description: {
        content: '',
        updatedAt: '',
        subType: '',
        createdAt: '',
        id: '',
        type: '',
        title: '',
      },
      descriptionDetail: [],
      image: {
        content: '',
        updatedAt: '',
        subType: '',
        createdAt: '',
        id: '',
        type: '',
        title: '',
      },
    });
  const [symbolPhilosophyData, setSymbolPhilosophyData] =
    useState<PhilosophyProps>({
      description: {
        content: '',
        updatedAt: '',
        subType: '',
        createdAt: '',
        id: '',
        type: '',
        title: '',
      },
      descriptionDetail: [],
      image: {
        content: '',
        updatedAt: '',
        subType: '',
        createdAt: '',
        id: '',
        type: '',
        title: '',
      },
    });

  const visiColumns = [
    {
      title: 'Deskripsi',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: () => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_VISI)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];

  const phylosophyColumn = [
    {
      title: 'Deskripsi',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: NormalProps, index: number) => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_FILOSOFI_LOGO + '/' + record.id)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];

  const LogoImageColumns = [
    {
      title: 'Foto Banner',
      key: 'url',
      dataIndex: 'url',
      render: (text: string, _: any, index: number) => (
        <>
          <Button onClick={() => setIsModalOpen({ [index]: true })}>
            Lihat Foto
          </Button>
          <Modal
            onCancel={() => setIsModalOpen({ [index]: false })}
            visible={isModalOpen[index] || false}
            footer={null}
          >
            <img
              src={text}
              alt="Foto Banner"
              style={{ width: '-webkit-fill-available' }}
            />
          </Modal>
        </>
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any, index: number) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_FILOSOFI_LOGO + '/' + record.id)}
          >
            Ubah
          </Button>
        </Space>
      ),
    },
  ];

  const misiColumns = [
    {
      title: 'No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Icon',
      dataIndex: 'url',
      render: (_: string, record: any) => (
        <PreviewImage
          key="key"
          buttonName="Lihat Icon"
          mediaUrl={record.url}
        ></PreviewImage>
      ),
    },
    {
      title: 'Deskripsi',
      dataIndex: 'content',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_MISI + '/' + record.id)}
          >
            Ubah
          </Button>
        </Space>
      ),
    },
  ];

  const getVisionMisionData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/about-us/vision-mission',
      }).then((response) => {
        if (!response.error) {
          setVisionData(response.vision || {});
          setMisionData(response.mission || []);
          setQuickLinkList(response.quickLinks || []);
          setColorPhilosophyData(response.colorPhilosophy || {});
          setSymbolPhilosophyData(response.symbolPhilosophy || {});
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getVisionMisionData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title={<Title level={4}>Visi</Title>}>
        <Table
          columns={visiColumns}
          dataSource={[visionData]}
          pagination={false}
        />
      </Card>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Misi
            </Title>
            <Button onClick={() => navigate(ADD_MISI)}>Tambah Baru</Button>
          </Space>
        }
      >
        <Table
          columns={misiColumns}
          dataSource={misionData}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Filosofi Logo - Deskripsi</Title>}>
        <Table
          columns={phylosophyColumn}
          dataSource={[
            colorPhilosophyData.description,
            symbolPhilosophyData.description,
          ]}
          pagination={false}
        />
      </Card>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Filosofi Logo - Detail
            </Title>
            <Button onClick={() => navigate(ADD_FILOSOFI_LOGO)}>
              Tambah Baru
            </Button>
          </Space>
        }
      >
        <Table
          columns={phylosophyColumn}
          dataSource={[
            ...colorPhilosophyData.descriptionDetail,
            ...symbolPhilosophyData.descriptionDetail,
          ]}
          pagination={false}
        />
      </Card>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Filosofi Logo - Gambar
            </Title>
          </Space>
        }
      >
        <Table
          columns={LogoImageColumns}
          dataSource={[colorPhilosophyData.image, symbolPhilosophyData.image]}
          pagination={false}
        />
      </Card>
      <CategorySection
        data={quickLinkList
          .filter((ql) => ql.pageName === 'VisionMission')
          .map((ql, i) => ({ ...ql, key: i + 1 }))}
        addLinkUrl={ADD_KATEGORI_VISI_MISI}
        editLinkUrl={EDIT_KATEGORI_VISI_MISI}
        onRefetch={getVisionMisionData}
      />
    </Space>
  );
};

export default VisionMision;
