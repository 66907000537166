import {
  ACARA,
  ALF,
  ARTIKEL,
  BERITA,
  DAFTAR_MEMBER_BARU,
  DASHBOARD,
  GALERI,
  KAJIAN_DAN_KONSULTASI,
  KONTAK,
  MANAJEMEN,
  MANAJEMEN_MEMBER,
  MEMBERSHIP,
  NEWSLETTER,
  PELATIHAN,
  PUBLIKASI,
  SEJARAH,
  VISI_MISI,
  WEBINAR,
} from '@constants/urls';

export const MENUS = [
  {
    name: 'Dashboard',
    url: DASHBOARD,
    submenu: [],
  },
  {
    name: 'Tentang Kami',
    url: '',
    submenu: [
      {
        name: 'Sejarah & Pendiri',
        url: SEJARAH,
      },
      {
        name: 'Visi & Misi',
        url: VISI_MISI,
      },
      {
        name: 'Manajemen',
        url: MANAJEMEN,
      },
      {
        name: 'Astagatra Leaders Forum',
        url: ALF,
      },
      {
        name: 'Keanggotaan',
        url: MEMBERSHIP,
      },
    ],
  },
  {
    name: 'Produk Kami',
    url: '',
    submenu: [
      {
        name: 'Kajian & Konsultasi',
        url: KAJIAN_DAN_KONSULTASI,
      },
      {
        name: 'Pelatihan',
        url: PELATIHAN,
      },
      {
        name: 'Inovasi Bisnis Berkelanjutan',
        url: WEBINAR,
      },
    ],
  },
  {
    name: 'Layanan',
    url: '',
    submenu: [
      {
        name: 'Artikel',
        url: ARTIKEL,
      },
      {
        name: 'Publikasi',
        url: PUBLIKASI,
      },
      {
        name: 'Berita',
        url: BERITA,
      },
      {
        name: 'Galeri',
        url: GALERI,
      },
      {
        name: 'Acara',
        url: ACARA,
      },
    ],
  },
  {
    name: 'Kontak',
    url: KONTAK,
    submenu: [],
  },
  {
    name: 'Newsletter',
    url: NEWSLETTER,
    submenu: [],
  },
  {
    name: 'Member',
    url: '',
    submenu: [
      {
        name: 'Manajemen Member',
        url: MANAJEMEN_MEMBER,
      },
      {
        name: 'Daftar Member Baru',
        url: DAFTAR_MEMBER_BARU,
      },
    ],
  },
];
