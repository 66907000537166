import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Typography,
  Upload,
} from 'antd';

import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import CustomTitle from '@components/Title';

import { KONTAK } from '@constants/urls';

import fetchRequest, { fetchFormData } from '@utils/fetcher';

const { Title } = Typography;

const PLATFORMS = ['Facebook', 'Instagram', 'Youtube', 'Twitter', 'Whatsapp'];

const EditContact = () => {
  const [comProf, setComProf] = useState<any[]>([]);
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fax, setFax] = useState('');
  const [email, setEmail] = useState('');

  const [socialMediaForm] = Form.useForm();

  const navigate = useNavigate();

  const handlePdfUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setComProf(fileList);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    if (comProf) {
      formData.append('file', comProf[0]?.originFileObj || comProf[0]?.url);
    }
    formData.append('address', address);
    formData.append('phone', phoneNumber);
    formData.append('fax', fax);
    formData.append('email', email);

    const smlist = socialMediaForm.getFieldValue('socialMedia');

    console.log(smlist);

    if (smlist.length > 0) {
      const sosMedList = smlist.map((sm: Record<string, string>) => {
        return {
          platformName: sm.platform || 'Facebook',
          accountName: sm.account,
        };
      });
      const sosMed = {
        items: sosMedList,
      };

      formData.append('socialMedia', JSON.stringify(sosMed));
    }

    try {
      await fetchFormData({
        method: 'POST',
        path: 'resource/contact',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(KONTAK);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const getContactData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/contact',
      }).then((response) => {
        if (!response.error) {
          setAddress(response.address || '');
          setEmail(response.email || '');
          setPhoneNumber(response.phone || '');
          setFax(response.fax || '');
          if (response.companyCatalog) {
            setComProf([
              {
                uid: '-1',
                name: 'Company Profile.pdf',
                status: 'done',
                url: response.companyCatalog,
              },
            ]);
          }

          if (response.socialMedia) {
            const toBeStored = response.socialMedia.map(
              (sm: Record<string, string>) => {
                return {
                  platform: sm.platformName,
                  account: sm.accountName,
                };
              }
            );

            socialMediaForm.setFieldsValue({ socialMedia: toBeStored });
          }
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getContactData();
  }, []);

  return (
    <Row>
      <Col span={16} offset={4}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle
              name="Detail Kontak"
              url={KONTAK}
              toolTip="Unggah katalog dalam bentuk file .pdf maksimal 25mb."
            />
            <Title level={5} style={{ marginTop: 10 }}>
              Informasi Kantor
            </Title>
            <Input
              addonBefore="Alamat Kantor"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <Input
              addonBefore="Telepon"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <Input
              addonBefore="Fax"
              value={fax}
              onChange={(e) => setFax(e.target.value)}
            />
            <Input
              addonBefore="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Title level={5} style={{ marginTop: 10 }}>
              Sosial Media
            </Title>
            <Form
              name="addSocialMedia"
              autoComplete="off"
              form={socialMediaForm}
            >
              <Form.List name="socialMedia">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{ display: 'flex', marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'platform']}
                          rules={[
                            {
                              required: true,
                              message: 'Jenis sosial media',
                            },
                          ]}
                        >
                          <Select defaultValue={PLATFORMS[0]}>
                            {PLATFORMS.map((pl) => (
                              <Select.Option key={pl} value={pl}>
                                {pl}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'account']}
                          rules={[
                            {
                              required: true,
                              message: 'Nama sosial media harus diisi',
                            },
                          ]}
                        >
                          <Input placeholder="Nama sosial media" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Tambah Sosial Media
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
            <Title level={5}>Profil Perusahaan</Title>
            <Upload
              listType="text"
              maxCount={1}
              accept=".pdf"
              fileList={comProf}
              onChange={handlePdfUpload}
              beforeUpload={()=> false}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditContact;
