import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
  Card,
  Row,
  Col,
  Space,
  Button,
  message,
  Upload,
  Typography,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import CustomTitle from '@components/Title';

import { DASHBOARD } from '@constants/urls';

import fetchRequest, { fetchFormData } from '@utils/fetcher';

const { Title } = Typography;

const EditBanner = () => {
  const [file, setFile] = useState<any[]>([]);

  const navigate = useNavigate();
  const location = useLocation();

  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFile(fileList);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    if (file) {
      formData.append('file', file[0]?.originFileObj || file[0]?.url);
    }

    try {
      await fetchFormData({
        method: 'POST',
        path: 'resource/homepage/banner',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(DASHBOARD);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getContent = async (id: string) => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource?id=' + id,
      }).then((response) => {
        if (!response.error) {
          if (response.url) {
            setFile([
              {
                uid: '-1',
                name: 'banner.png',
                status: 'done',
                url: response.url,
              },
            ]);
          }
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const bannerId = path[path.indexOf('edit') + 1];

      getContent(bannerId);
    }
  }, []);
  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle
              name={'Banner'}
              url={DASHBOARD}
              toolTip={`Ukuran Banner minimum yang dianjurkan: 1440 x 678. Menerima jenis file .jpg, .jpeg, dan .png`}
            />
            <Title level={5}>Tampilan Banner</Title>
            <Upload
              listType="picture"
              maxCount={1}
              accept=".png, .jpg, .jpeg"
              fileList={file}
              onChange={handleImageUpload}
              beforeUpload={()=> false}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditBanner;
