import { useState, useEffect } from 'react';

import { message, Space } from 'antd';

import fetchRequest from '@utils/fetcher';

import { HomepageProps } from '@customTypes/dashboard';

import BannerCard from './BannerCard';
import CarouselCard from './CarouselCard';
import NewProductCard from './NewProductCard';
import StrengthCard from './StrengthCard';
import FocusCard from './FocusCard';
import TestimonyCard from './TestimonyCard';
import LoginCard from './LoginCard';

const Dashboard = () => {
  const [bannerData, setBannerData] = useState<HomepageProps>({
    updatedAt: '',
    subType: 'Banner',
    createdAt: '',
    url: '',
    id: '',
    type: 'Homepage',
    title: '',
  });
  const [carouselData, setCarouselData] = useState<HomepageProps[]>([]);
  const [newProductData, setnewProductData] = useState<HomepageProps[]>([]);
  const [strengthData, setStregthData] = useState<HomepageProps[]>([]);
  const [testimoniData, setTestimoniData] = useState<HomepageProps[]>([]);
  const [loginBannerData, setLoginBannerData] = useState<HomepageProps>({
    updatedAt: '',
    subType: 'Banner',
    createdAt: '',
    url: '',
    id: '',
    type: 'Homepage',
    title: '',
  });

  const getData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'resource/homepage' }).then(
        (response) => {
          if (!response.error) {
            setBannerData(response.banner);
            setCarouselData(response.carousel || []);
            setnewProductData(response.newProduct || []);
            setStregthData(response.ourStrength || []);
            setTestimoniData(response.testimoni || []);
            setLoginBannerData(response.loginBanner);
          } else {
            message.error(response.error.message);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <BannerCard {...bannerData} />
      <CarouselCard data={carouselData} />
      <NewProductCard data={newProductData}/>
      <StrengthCard data={strengthData} onDelete={getData}  />
      <TestimonyCard data={testimoniData} onDelete={getData} />
      <LoginCard {...loginBannerData} />
    </Space>
  );
};

export default Dashboard;
