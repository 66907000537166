import { Route, Routes } from 'react-router-dom';

import { Row, Col } from 'antd';

import CustomNavbar from '@components/Navbar';
import Sidebar from '@components/Sidebar';

import Dashboard from './Dashboard';
import History from './AboutUs/routes/History';
import AddHistoryCategory from './AboutUs/routes/History/routes/AddCategory';
import EditHistoryCategory from './AboutUs/routes/History/routes/EditCategory';
import EditHistory from './AboutUs/routes/History/routes/EditHistory';
import Article from './Service/routes/Article';
import Publication from './Service/routes/Publication';
import News from './Service/routes/News';
import UpsertArticle from './Service/routes/Article/routes/UpsertArticle';
import AddArticleCategory from './Service/routes/Article/routes/AddCategory';
import EditArticleCategory from './Service/routes/Article/routes/EditCategory';
import UpsertNews from './Service/routes/News/routes/UpsertNews';
import UpsertPublication from './Service/routes/Publication/routes/UpsertPublication';
import AddNewsCategory from './Service/routes/News/routes/AddCategory';
import EditNewsCategory from './Service/routes/News/routes/EditCategory';
import AddPublicationCategory from './Service/routes/Publication/routes/AddCategory';
import EditPublicationCategory from './Service/routes/Publication/routes/EditCategory';
import Event from './Service/routes/Event';
import UpsertEvent from './Service/routes/Event/routes/UpsertEvent';
import Webinar from './Product/routes/Webinar';
import UpsertWebinar from './Product/routes/Webinar/routes/UpsertWebinar';
import AddWebinarCategory from './Product/routes/Webinar/routes/AddCategory';
import EditWebinarCategory from './Product/routes/Webinar/routes/EditCategory';
import Newsletter from './Newsletter';
import UpdateHeaderFooter from './Newsletter/routes/UpdateHeaderFooter';
import CreateNewsletter from './Newsletter/routes/CreateNewsletter';
import Consultation from './Product/routes/Consultation';
import EditCatalogConsultation from './Product/routes/Consultation/routes/EditCatalog';
import EditConsultationCategory from './Product/routes/Consultation/routes/EditCategory';
import AddConsultationCategory from './Product/routes/Consultation/routes/AddCategory';
import Training from './Product/routes/Training';
import EditTrainingCategory from './Product/routes/Training/routes/EditCategory';
import AddTrainingCategory from './Product/routes/Training/routes/AddCategory';
import EditCatalogTraining from './Product/routes/Training/routes/EditCatalog';
import UpsertTestimony from './Dashboard/routes/UpsertTestimony';
import EditBanner from './Dashboard/routes/EditBanner';
import EditStrength from './Dashboard/routes/EditStrength';
import EditFocus from './Dashboard/routes/EditFocus';
import EditCarousel from './Dashboard/routes/EditCarousel';
import EditNewProduct from './Dashboard/routes/EditNewProduct';
import VisionMision from './AboutUs/routes/VisionMision';
import EditVision from './AboutUs/routes/VisionMision/routes/EditVision';
import EditMision from './AboutUs/routes/VisionMision/routes/EditMision';
import Management from './AboutUs/routes/Management';
import EditManagement from './AboutUs/routes/Management/routes/EditManagement';
import ALFPage from './AboutUs/routes/ALF';
import EditDescALF from './AboutUs/routes/ALF/routes/EditALF';
import EditALFPeople from './AboutUs/routes/ALF/routes/EditALFPeople';
import Membership from './AboutUs/routes/Membership';
import EditDescMembership from './AboutUs/routes/Membership/routes/EditContent';
import EditBenefit from './AboutUs/routes/Membership/routes/EditBenefit';
import MemberManagement from './Member/routes/MemberManagement';
import NewMember from './Member/routes/NewMember.tsx';
import MemberDetail from './Member/routes/MemberDetail';
import ContactUs from './ContactUs';
import EditContact from './ContactUs/routes/EditContact';
import Gallery from './Service/routes/Gallery';
import UpsertGallery from './Service/routes/Gallery/routes/UpsertGallery';
import AddVisionMisionCategory from './AboutUs/routes/VisionMision/routes/AddCategory';
import EditVisionMisionCategory from './AboutUs/routes/VisionMision/routes/EditCategory';
import AddALFCategory from './AboutUs/routes/ALF/routes/AddCategory';
import EditALFCategory from './AboutUs/routes/ALF/routes/EditCategory';
import EditMembershipCategory from './AboutUs/routes/Membership/routes/EditCategory';
import AddMembershipCategory from './AboutUs/routes/Membership/routes/AddCategory';
import EditGaleryCategory from './Service/routes/Gallery/routes/EditCategory';
import AddGaleryCategory from './Service/routes/Gallery/routes/AddCategory';
import EditStrategicBenefit from './Product/routes/Consultation/routes/EditStrategicBenefit';
import EditAdvisoryBenefit from './Product/routes/Consultation/routes/EditAdvisoryBenefit';
import EditAdvisoryDesc from './Product/routes/Consultation/routes/EditAdvisoryDesc';
import EditStrategicDesc from './Product/routes/Consultation/routes/EditStrategicDesc';
import EditAdvisoryItem from './Product/routes/Consultation/routes/EditAdvisoryItem';
import EditStrategicItem from './Product/routes/Consultation/routes/EditStrategicItem';
import EditTrainingItem from './Product/routes/Training/routes/EditTrainingItem';
import EditFounders from './AboutUs/routes/History/routes/EditFounders';
import EditMainPictureConsultation from './Product/routes/Consultation/routes/EditMainPictureConsultation';
import EditMainPictureCatalog from './Product/routes/Consultation/routes/EditCatalogMainPicture';
import EditMainDescConsultation from './Product/routes/Consultation/routes/EditMainDescriptionConsultation';
import EditWhyUsDescConsultation from './Product/routes/Consultation/routes/EditWhyUsDescConsultation';
import EditApproachDesc from './Product/routes/Consultation/routes/EditApproachDesc';
import EditApproachItem from './Product/routes/Consultation/routes/EditApproachItem';
import EditTrainingDesc from './Product/routes/Training/routes/EditTrainingDesc';
import EditPictureTraining from './Product/routes/Training/routes/EditPictureTraining';
import EditMethodItem from './Product/routes/Training/routes/EditMethodItem';
import EditWebinarDesc from './Product/routes/Webinar/routes/EditWebinarDesc';
import EditLoginBanner from './Dashboard/routes/EditLoginBanner';
import EditPhilosophy from './AboutUs/routes/VisionMision/routes/EditPhilosophy';
import ChangePassword from './ChangePassword';

import {
  DASHBOARD,
  SEJARAH,
  ADD_KATEGORI_SEJARAH,
  EDIT_KATEGORI_SEJARAH,
  EDIT_SEJARAH,
  ARTIKEL,
  PUBLIKASI,
  BERITA,
  EDIT_ARTIKEL,
  ADD_ARTIKEL,
  ADD_KATEGORI_ARTIKEL,
  EDIT_KATEGORI_ARTIKEL,
  EDIT_KATEGORI_PUBLIKASI,
  EDIT_KATEGORI_BERITA,
  ADD_KATEGORI_BERITA,
  ADD_KATEGORI_PUBLIKASI,
  EDIT_PUBLIKASI,
  EDIT_BERITA,
  ADD_BERITA,
  ADD_PUBLIKASI,
  ACARA,
  ADD_ACARA,
  EDIT_ACARA,
  WEBINAR,
  EDIT_WEBINAR,
  EDIT_KATEGORI_WEBINAR,
  ADD_KATEGORI_WEBINAR,
  NEWSLETTER,
  EDIT_HEADER_FOOTER,
  ADD_NEWSLETTER,
  KAJIAN_DAN_KONSULTASI,
  EDIT_KATEGORI_KAJIAN_DAN_KONSULTASI,
  ADD_KATEGORI_KAJIAN_DAN_KONSULTASI,
  PELATIHAN,
  ADD_KATEGORI_PELATIHAN,
  EDIT_KATEGORI_PELATIHAN,
  EDIT_KATALOG_KAJIAN_DAN_KONSULTASI,
  EDIT_KATALOG_PELATIHAN,
  EDIT_TESTIMONI,
  ADD_TESTIMONI,
  EDIT_BANNER,
  EDIT_KEUNGGULAN_KAMI,
  EDIT_FOKUS_KAMI,
  EDIT_CAROUSEL,
  ADD_CAROUSEL,
  EDIT_NEW_PRODUCT,
  ADD_NEW_PRODUCT,
  ADD_KEUNGGULAN_KAMI,
  VISI_MISI,
  EDIT_VISI,
  EDIT_MISI,
  MANAJEMEN,
  EDIT_MANAJEMEN,
  ALF,
  EDIT_DESKRIPSI_ALF,
  EDIT_JAJARAN_AHLI,
  MEMBERSHIP,
  EDIT_DESKRIPSI_MEMBERSHIP,
  EDIT_KEUNTUNGAN,
  MANAJEMEN_MEMBER,
  DAFTAR_MEMBER_BARU,
  EDIT_MANAJEMEN_MEMBER_AKTIF,
  KONTAK,
  EDIT_KONTAK,
  GALERI,
  EDIT_GALERI,
  EDIT_KATEGORI_VISI_MISI,
  ADD_KATEGORI_VISI_MISI,
  EDIT_KATEGORI_ALF,
  ADD_KATEGORI_ALF,
  EDIT_KATEGORI_MEMBERSHIP,
  ADD_KATEGORI_MEMBERSHIP,
  ADD_GALERI,
  ADD_MISI,
  ADD_JAJARAN_AHLI,
  ADD_WEBINAR,
  EDIT_KATEGORI_GALERI,
  ADD_KATEGORI_GALERI,
  EDIT_STRATEGIC_BENEFIT,
  EDIT_ADVISORY_BENEFIT,
  EDIT_ADVISORY_DESC,
  EDIT_STRATEGIC_DESC,
  EDIT_ADVISORY_ITEM,
  EDIT_STRATEGIC_ITEM,
  EDIT_LAYANAN_PELATIHAN,
  ADD_PENDIRI,
  EDIT_PENDIRI,
  EDIT_GAMBAR_KONSULTASI,
  EDIT_GAMBAR_KATALOG,
  EDIT_DESKRIPSI_KONSULTASI,
  EDIT_KENAPA_KAMI_DESC_KONSULTASI,
  EDIT_PENDEKATAN_DESC_KONSULTASI,
  EDIT_PENDEKETAN_ITEM_KONSULTASI,
  EDIT_PELATIHAN_DESC,
  EDIT_GAMBAR_PELATIHAN,
  EDIT_METODE_PELATIHAN,
  EDIT_DESC_WEBINAR,
  EDIT_LOGIN_BANNER,
  EDIT_FILOSOFI_LOGO,
  ADD_FILOSOFI_LOGO,
  CHANGE_PASSWORD,
} from '@constants/urls';

const GeneralRoutes = () => {
  return (
    <>
      <CustomNavbar />
      <Row gutter={4} style={{ height: '90vh' }}>
        <Col span={4}>
          <Sidebar />
        </Col>
        <Col span={20} style={{ backgroundColor: '#E5E5E5' }}>
          <div style={{ padding: '50px' }}>
            <Routes>
              <Route path={DASHBOARD} element={<Dashboard />} />
              <Route path={SEJARAH} element={<History />} />
              <Route
                path={ADD_KATEGORI_SEJARAH}
                element={<AddHistoryCategory />}
              />
              <Route
                path={EDIT_KATEGORI_SEJARAH + '/*'}
                element={<EditHistoryCategory />}
              />
              <Route path={EDIT_SEJARAH} element={<EditHistory />} />
              <Route path={ADD_PENDIRI} element={<EditFounders />} />
              <Route path={EDIT_PENDIRI + '/*'} element={<EditFounders />} />
              <Route path={ARTIKEL} element={<Article />} />
              <Route path={PUBLIKASI} element={<Publication />} />
              <Route path={BERITA} element={<News />} />
              <Route path={EDIT_ARTIKEL + '/*'} element={<UpsertArticle />} />
              <Route path={ADD_ARTIKEL} element={<UpsertArticle />} />
              <Route path={ADD_BERITA} element={<UpsertNews />} />
              <Route path={ADD_PUBLIKASI} element={<UpsertPublication />} />
              <Route path={EDIT_BERITA + '/*'} element={<UpsertNews />} />
              <Route
                path={EDIT_PUBLIKASI + '/*'}
                element={<UpsertPublication />}
              />
              <Route
                path={ADD_KATEGORI_ARTIKEL}
                element={<AddArticleCategory />}
              />
              <Route
                path={EDIT_KATEGORI_ARTIKEL + '/*'}
                element={<EditArticleCategory />}
              />
              <Route
                path={EDIT_KATEGORI_PUBLIKASI + '/*'}
                element={<EditPublicationCategory />}
              />
              <Route
                path={EDIT_KATEGORI_BERITA + '/*'}
                element={<EditNewsCategory />}
              />
              <Route path={ADD_KATEGORI_BERITA} element={<AddNewsCategory />} />
              <Route
                path={ADD_KATEGORI_PUBLIKASI}
                element={<AddPublicationCategory />}
              />
              <Route
                path={EDIT_PUBLIKASI + '/*'}
                element={<UpsertPublication />}
              />
              <Route path={EDIT_BERITA + '/*'} element={<UpsertNews />} />
              <Route path={ACARA} element={<Event />} />
              <Route path={ADD_ACARA} element={<UpsertEvent />} />
              <Route path={EDIT_ACARA + '/*'} element={<UpsertEvent />} />
              <Route path={WEBINAR} element={<Webinar />} />
              <Route path={EDIT_WEBINAR + '/*'} element={<UpsertWebinar />} />
              <Route path={ADD_WEBINAR} element={<UpsertWebinar />} />
              <Route
                path={EDIT_KATEGORI_WEBINAR + '/*'}
                element={<EditWebinarCategory />}
              />
              <Route
                path={ADD_KATEGORI_WEBINAR}
                element={<AddWebinarCategory />}
              />
              <Route path={NEWSLETTER} element={<Newsletter />} />
              <Route
                path={EDIT_HEADER_FOOTER + '/Header'}
                element={<UpdateHeaderFooter />}
              />
              <Route
                path={EDIT_HEADER_FOOTER + '/Footer'}
                element={<UpdateHeaderFooter />}
              />
              <Route path={ADD_NEWSLETTER} element={<CreateNewsletter />} />
              <Route path={KAJIAN_DAN_KONSULTASI} element={<Consultation />} />
              <Route
                path={ADD_KATEGORI_KAJIAN_DAN_KONSULTASI}
                element={<AddConsultationCategory />}
              />
              <Route
                path={EDIT_KATEGORI_KAJIAN_DAN_KONSULTASI}
                element={<EditConsultationCategory />}
              />
              <Route
                path={EDIT_KATALOG_KAJIAN_DAN_KONSULTASI}
                element={<EditCatalogConsultation />}
              />
              <Route path={PELATIHAN} element={<Training />} />
              <Route
                path={ADD_KATEGORI_PELATIHAN}
                element={<AddTrainingCategory />}
              />
              <Route
                path={EDIT_KATEGORI_PELATIHAN}
                element={<EditTrainingCategory />}
              />
              <Route
                path={EDIT_KATALOG_PELATIHAN}
                element={<EditCatalogTraining />}
              />
              <Route
                path={EDIT_TESTIMONI + '/*'}
                element={<UpsertTestimony />}
              />
              <Route path={ADD_TESTIMONI} element={<UpsertTestimony />} />
              <Route path={EDIT_BANNER + '/*'} element={<EditBanner />} />
              <Route
                path={EDIT_KEUNGGULAN_KAMI + '/*'}
                element={<EditStrength />}
              />
              <Route path={EDIT_FOKUS_KAMI + '/*'} element={<EditFocus />} />
              <Route path={EDIT_CAROUSEL + '/*'} element={<EditCarousel />} />
              <Route path={ADD_CAROUSEL} element={<EditCarousel />} />
              <Route path={EDIT_NEW_PRODUCT + '/*'} element={<EditNewProduct />} />
              <Route path={ADD_NEW_PRODUCT} element={<EditNewProduct/>} />
              <Route path={ADD_KEUNGGULAN_KAMI} element={<EditStrength />} />
              <Route path={VISI_MISI} element={<VisionMision />} />
              <Route path={EDIT_VISI} element={<EditVision />} />
              <Route path={ADD_MISI} element={<EditMision />} />
              <Route path={EDIT_MISI + '/*'} element={<EditMision />} />
              <Route path={MANAJEMEN} element={<Management />} />
              <Route
                path={EDIT_MANAJEMEN + '/*'}
                element={<EditManagement />}
              />
              <Route path={ALF} element={<ALFPage />} />
              <Route path={EDIT_DESKRIPSI_ALF} element={<EditDescALF />} />
              <Route
                path={EDIT_JAJARAN_AHLI + '/*'}
                element={<EditALFPeople />}
              />
              <Route path={ADD_JAJARAN_AHLI} element={<EditALFPeople />} />
              <Route path={MEMBERSHIP} element={<Membership />} />
              <Route
                path={EDIT_DESKRIPSI_MEMBERSHIP}
                element={<EditDescMembership />}
              />
              <Route path={EDIT_KEUNTUNGAN} element={<EditBenefit />} />
              <Route path={MANAJEMEN_MEMBER} element={<MemberManagement />} />
              <Route path={DAFTAR_MEMBER_BARU} element={<NewMember />} />
              <Route
                path={EDIT_MANAJEMEN_MEMBER_AKTIF + '/*'}
                element={<MemberDetail />}
              />
              <Route path={KONTAK} element={<ContactUs />} />
              <Route path={EDIT_KONTAK} element={<EditContact />} />
              <Route path={GALERI} element={<Gallery />} />
              <Route path={EDIT_GALERI + '/*'} element={<UpsertGallery />} />
              <Route path={ADD_GALERI} element={<UpsertGallery />} />
              <Route
                path={EDIT_KATEGORI_GALERI + '/*'}
                element={<EditGaleryCategory />}
              />
              <Route
                path={ADD_KATEGORI_GALERI}
                element={<AddGaleryCategory />}
              />
              <Route
                path={EDIT_KATEGORI_VISI_MISI + '/*'}
                element={<EditVisionMisionCategory />}
              />
              <Route
                path={ADD_KATEGORI_VISI_MISI}
                element={<AddVisionMisionCategory />}
              />
              <Route
                path={EDIT_KATEGORI_ALF + '/*'}
                element={<EditALFCategory />}
              />
              <Route path={ADD_KATEGORI_ALF} element={<AddALFCategory />} />
              <Route
                path={EDIT_KATEGORI_MEMBERSHIP + '/*'}
                element={<EditMembershipCategory />}
              />
              <Route
                path={ADD_KATEGORI_MEMBERSHIP}
                element={<AddMembershipCategory />}
              />
              <Route
                path={EDIT_STRATEGIC_BENEFIT + '/*'}
                element={<EditStrategicBenefit />}
              />
              <Route
                path={EDIT_ADVISORY_BENEFIT + '/*'}
                element={<EditAdvisoryBenefit />}
              />
              <Route
                path={EDIT_ADVISORY_DESC + '/*'}
                element={<EditAdvisoryDesc />}
              />
              <Route
                path={EDIT_STRATEGIC_DESC + '/*'}
                element={<EditStrategicDesc />}
              />
              <Route
                path={EDIT_ADVISORY_ITEM + '/*'}
                element={<EditAdvisoryItem />}
              />
              <Route
                path={EDIT_STRATEGIC_ITEM + '/*'}
                element={<EditStrategicItem />}
              />
              <Route
                path={EDIT_LAYANAN_PELATIHAN + '/*'}
                element={<EditTrainingItem />}
              />
              <Route
                path={EDIT_GAMBAR_KONSULTASI + '/*'}
                element={<EditMainPictureConsultation />}
              />
                <Route
                path={EDIT_GAMBAR_KATALOG + '/*'}
                element={<EditMainPictureCatalog />}
              />
              <Route
                path={EDIT_DESKRIPSI_KONSULTASI + '/*'}
                element={<EditMainDescConsultation />}
              />
              <Route
                path={EDIT_KENAPA_KAMI_DESC_KONSULTASI + '/*'}
                element={<EditWhyUsDescConsultation />}
              />
              <Route
                path={EDIT_PENDEKATAN_DESC_KONSULTASI + '/*'}
                element={<EditApproachDesc />}
              />
              <Route
                path={EDIT_PENDEKETAN_ITEM_KONSULTASI + '/*'}
                element={<EditApproachItem />}
              />
              <Route
                path={EDIT_PELATIHAN_DESC + '/*'}
                element={<EditTrainingDesc />}
              />
              <Route
                path={EDIT_GAMBAR_PELATIHAN + '/*'}
                element={<EditPictureTraining />}
              />
              <Route
                path={EDIT_METODE_PELATIHAN + '/*'}
                element={<EditMethodItem />}
              />
              <Route path={EDIT_DESC_WEBINAR} element={<EditWebinarDesc />} />
              <Route
                path={EDIT_LOGIN_BANNER + '/*'}
                element={<EditLoginBanner />}
              />
              <Route
                path={EDIT_FILOSOFI_LOGO + '/*'}
                element={<EditPhilosophy />}
              />
              <Route path={ADD_FILOSOFI_LOGO} element={<EditPhilosophy />} />
              <Route path={CHANGE_PASSWORD} element={<ChangePassword />} />
            </Routes>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default GeneralRoutes;
