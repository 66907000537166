import { useState } from 'react';
import { useNavigate } from 'react-router';

import { Space, Card, Table, Button, Typography, Modal, message } from 'antd';

import { HomepageProps } from '@customTypes/dashboard';

import { ADD_TESTIMONI, EDIT_TESTIMONI } from '@constants/urls';

import DeleteButton from '@components/DeleteButton';

import fetchRequest from '@utils/fetcher';

const { Title } = Typography;

interface Props {
  data: HomepageProps[];
  onDelete: () => void;
}

const TestimonyCard = (props: Props) => {
  const { data, onDelete } = props;

  const navigate = useNavigate();

  const handleDelete = async (id: string) => {
    try {
      await fetchRequest({
        method: 'DELETE',
        path: 'resource?id=' + id,
      }).then((response) => {
        if (!response.error) {
          onDelete();
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: 'Nama',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Jabatan',
      key: 'otherData',
      dataIndex: 'otherData',
      render: (text: { position: string }) => text.position,
    },
    {
      title: 'Konten Testimoni',
      key: 'content',
      dataIndex: 'content',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_TESTIMONI + '/' + record.id)}
          >
            Ubah
          </Button>
          <DeleteButton onDelete={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];
  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Testimoni
            </Title>
            <Button onClick={() => navigate(ADD_TESTIMONI)}>Tambah Baru</Button>
          </Space>
        }
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </Card>
    </Space>
  );
};

export default TestimonyCard;
