import { useEffect, useState } from 'react';

import {
  Row,
  Col,
  Card,
  Space,
  Typography,
  Button,
  Tooltip,
  Avatar,
  Upload,
  message,
} from 'antd';

import { InfoOutlined, UploadOutlined } from '@ant-design/icons';

import CustomTitle from '@components/Title';

import { PELATIHAN } from '@constants/urls';
import fetchRequest, { fetchFormData } from '@utils/fetcher';
import { useNavigate } from 'react-router';

const { Title } = Typography;

const EditPictureTraining = () => {
  const [id, setId] = useState('');
  const [mediaUrl, setMediaUrl] = useState<any[]>([]);

  const navigate = useNavigate();

  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setMediaUrl(fileList);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    if (mediaUrl) {
      formData.append('file', mediaUrl[0]?.originFileObj || mediaUrl[0]?.url);
    }

    try {
      await fetchFormData({
        method: 'POST',
        path: 'resource/service/training/main-picture',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(PELATIHAN);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const getPictureData = async (id: string) => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource?id=' + id,
      }).then((response) => {
        setMediaUrl([
          {
            uid: '-1',
            name: 'mainPictureTraining.png',
            status: 'done',
            url: response.url,
          },
        ]);
      });
    } catch (error) {}
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const pictId = path[path.indexOf('edit') + 1];

      setId(pictId);
      getPictureData(pictId);
    }
  }, []);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space align="baseline">
              <CustomTitle name="Gambar Pelatihan" url={PELATIHAN} />
              <Tooltip title="Menerima jenis file: .jpg, .jpeg, .png. Untuk icon transparan gunakan .png">
                <Avatar
                  size={'small'}
                  icon={<InfoOutlined />}
                  style={{ backgroundColor: '#8C8C8C' }}
                ></Avatar>
              </Tooltip>
            </Space>
            <Title level={5}>Gambar</Title>
            <Upload
              listType="picture"
              maxCount={1}
              accept=".png, .jpg, .jpeg"
              fileList={mediaUrl}
              onChange={handleImageUpload}
              beforeUpload={()=> false}
            >
              <Button icon={<UploadOutlined />}>Unggah Icon</Button>
            </Upload>
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditPictureTraining;
