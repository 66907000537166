import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, message } from 'antd';

import { EDIT_KONTAK } from '@constants/urls';

import fetchRequest from '@utils/fetcher';
import { ContactProps } from '@customTypes/contact';

const { Title } = Typography;

type SocialMedia = {
  type: string;
  content: string;
};

const ContactUs = () => {
  const [contactData, setContactData] = useState<ContactProps>();
  const [socialMediaData, setSocialMediaData] = useState<SocialMedia[]>([]);

  const navigate = useNavigate();

  const contactColumns = [
    {
      title: 'Jenis',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Konten',
      key: 'content',
      dataIndex: 'content',
      render: (text: string) => {
        if (text === 'comProf') {
          return (
            <Button
              type="link"
              className="button-link-grey-border"
              style={{ width: '100%' }}
              onClick={() => window.open(contactData?.companyCatalog)}
            >
              Lihat Katalog
            </Button>
          );
        }
        return text;
      },
    },
  ];

  const dataColumns = [
    {
      type: 'ALAMAT KANTOR',
      content: contactData?.address,
    },
    {
      type: 'TELEPON',
      content: contactData?.phone,
    },
    {
      type: 'FAX',
      content: contactData?.fax,
    },
    {
      type: 'EMAIL',
      content: contactData?.email,
    },
    ...socialMediaData,
    {
      type: 'PROFIL PERUSAHAAN',
      content: 'comProf',
    },
  ];

  const getContactData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/contact',
      }).then((response) => {
        if (!response.error) {
          setContactData(response);
          setSocialMediaData(
            (response as ContactProps).socialMedia?.map((e) => {
              return {
                type: e.platformName.toUpperCase(),
                content: e.accountName,
              };
            })
          );
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getContactData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card>
        <Space direction="horizontal" align="end" style={{ marginBottom: 20 }}>
          <Title level={4} style={{ marginBottom: 0 }}>
            Kontak
          </Title>
          <Button
            danger
            type="link"
            onClick={() => navigate(EDIT_KONTAK)}
            className="button-link-grey-border"
          >
            Ubah
          </Button>
        </Space>
        <Table
          columns={contactColumns}
          dataSource={dataColumns}
          pagination={false}
        />
      </Card>
    </Space>
  );
};

export default ContactUs;
