import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, message } from 'antd';

import CategorySection from '@components/CategorySection';

import {
  ADD_KATEGORI_PELATIHAN,
  EDIT_GAMBAR_PELATIHAN,
  EDIT_KATALOG_PELATIHAN,
  EDIT_KATEGORI_PELATIHAN,
  EDIT_LAYANAN_PELATIHAN,
  EDIT_METODE_PELATIHAN,
  EDIT_PELATIHAN_DESC,
} from '@constants/urls';

import type { QuickLinkProps } from '@customTypes/quickLink';

import fetchRequest from '@utils/fetcher';
import { ConsulProps } from '@customTypes/service';
import PreviewImage from '@components/PreviewImage';

const { Title } = Typography;

const Training = () => {
  const [consulData, setConsulData] = useState<ConsulProps | {}>({});
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [consulItem, setConsulItem] = useState<ConsulProps[]>([]);
  const [mainDescData, setMainDescData] = useState<ConsulProps | {}>({});
  const [mainPicture, setMainPicture] = useState<ConsulProps | {}>({});
  const [methodItem, setMethodItem] = useState<ConsulProps[]>([]);


  const navigate = useNavigate();

  const consultationColumns = [
    {
      title: 'Konten Katalog',
      dataIndex: 'url',
      render: (_: string, record: any) => (
        <Button
          type="link"
          className="button-link-grey-border"
          style={{ width: '100%' }}
          onClick={() => window.open(record.url)}
        >
          Lihat Katalog
        </Button>
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      render: () => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_KATALOG_PELATIHAN)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];

  const otherColumns = (url: string) => {
    return [
    {
      title: 'Konten',
      dataIndex: 'content',
      render: (text: string) => (
        <div dangerouslySetInnerHTML={{ __html: text }} />
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      dataIndex: 'id',
      render: (text: string) => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(url + '/' + text)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];
};

const pictureColumns = (url: string) =>  [
  {
    title: 'Icon',
    dataIndex: 'url',
    render: (_: string, record: any) => (
      <PreviewImage
        key="key"
        buttonName="Lihat Icon"
        mediaUrl={record.url}
      ></PreviewImage>
    ),
  },
  {
    title: 'Aksi',
    key: 'action',
    dataIndex: 'id',
    render: (text: string) => (
      <Button
        type="link"
        className="button-link-grey"
        onClick={() => navigate(url + '/' + text)}
      >
        Ubah
      </Button>
    ),
    width: 150,
  },
  ];

  const getConsultationData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/service/training',
      }).then((response) => {
        if (!response.error) {
          setConsulData(response.training || {});
          setQuickLinkList(response.quickLinks || []);
          setConsulItem(response.trainingItem || []);
          setMainDescData(response.trainingMainDesc || {});
          setMainPicture(response.trainingMainPicture || {});
          setMethodItem(response.trainingMethod || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getConsultationData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title={<Title level={4}>Katalog</Title>}>
        <Table
          columns={consultationColumns}
          dataSource={[consulData]}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Deskripsi Pelatihan</Title>}>
        <Table
          columns={otherColumns(EDIT_PELATIHAN_DESC)}
          dataSource={[mainDescData]}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Gambar Pelatihan</Title>}>
        <Table
          columns={pictureColumns(EDIT_GAMBAR_PELATIHAN)}
          dataSource={[mainPicture]}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Layanan Pelatihan</Title>}>
        <Table
          columns={otherColumns(EDIT_LAYANAN_PELATIHAN)}
          dataSource={consulItem}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Metode Pelatihan</Title>}>
        <Table
          columns={otherColumns(EDIT_METODE_PELATIHAN)}
          dataSource={methodItem}
          pagination={false}
        />
      </Card>
      <CategorySection
        data={quickLinkList
          .filter((ql) => ql.pageName === 'Training')
          .map((ql, i) => ({ ...ql, key: i + 1 }))}
        addLinkUrl={ADD_KATEGORI_PELATIHAN}
        editLinkUrl={EDIT_KATEGORI_PELATIHAN}
        onRefetch={getConsultationData}
      />
    </Space>
  );
};

export default Training;
