import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Card, Row, Col, Space, Button, message, Input, Typography, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CustomTitle from '@components/Title';
import { DASHBOARD } from '@constants/urls';
import fetchRequest, { fetchFormData } from '@utils/fetcher';

const { TextArea } = Input;
const { Title } = Typography;

const EditNewProduct = () => {
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [linkTo, setLinkTo] = useState('');
  const [file, setFile] = useState<any[]>([]);
  const [existingImageUrl, setExistingImageUrl] = useState<string | null>(null); // State for existing image URL

  const navigate = useNavigate();
  const location = useLocation();

  // Handle image upload
  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1); // Ensure only one file is shown

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFile(fileList);
  };

  // Handle form submission
  const handleSubmit = async () => {
    const formData = new FormData();
  
    // Check if a new file is uploaded
    if (file.length > 0 && file[0]?.originFileObj) {
      formData.append('file', file[0].originFileObj); // If new file, add to FormData
    } else if (existingImageUrl) {
      formData.append('existingImageUrl', existingImageUrl); // If no new file, use existing image URL
    } else {
      formData.append('existingImageUrl', ''); // Send an empty value if no image at all
    }
  
    formData.append('title', title);
    formData.append('content', content);
    formData.append('linkTo', linkTo);
  
    if (id) {
      formData.append('id', id); // Include id in case of editing
    }
  
    try {
      const response = await fetchFormData({
        method: 'POST',
        path: 'resource/homepage/new-product',
        data: formData,
      });
  
      if (!response.error) {
        message.success('Product saved successfully.');
        navigate(DASHBOARD);
      } else {
        message.error(response.error.message);
      }
    } catch (error: any) {
      message.error(error.message || 'An unexpected error occurred.');
    }
  };
  
  // Fetch content for editing
  const getContent = async (id: string) => {
    try {
      const response = await fetchRequest({
        method: 'GET',
        path: 'resource?id=' + id,
      });
  
      if (!response.error) {
        if (response.url) {
          setExistingImageUrl(response.url); // Set existing image URL
          setFile([
            {
              uid: '-1',
              name: 'cover.png',
              status: 'done',
              url: response.url,
            },
          ]);
        }
        setTitle(response.title || '');
        setContent(response.content || '');
        setLinkTo(response?.otherData?.linkTo || '');
      } else {
        message.error(response.error.message);
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const newProductId = path[path.indexOf('edit') + 1];
      setId(newProductId); // Set ID for editing
      getContent(newProductId); // Fetch content for editing
    }
  }, [location.pathname]);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle
              name="New Product"
              url={DASHBOARD}
              toolTip="Acceptable file types: .jpg, .jpeg, .png. Max file size: 25mb."
            />
            <Title level={5}>Image</Title>
            <Upload
              listType="picture"
              maxCount={1}
              accept=".png, .jpg, .jpeg"
              fileList={file}
              onChange={handleImageUpload}
              beforeUpload={() => false}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>

            <Title level={5}>Title</Title>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
            <Title level={5}>Short Description</Title>
            <TextArea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              rows={4}
             
            />
            <Title level={5}>Button URL</Title>
            <TextArea
              value={linkTo}
              onChange={(e) => setLinkTo(e.target.value)}
              rows={4}
            
            />
            <Row justify="end">
              <Col>
                <Button type="primary" onClick={handleSubmit}>
                  Save Changes
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditNewProduct;
