import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
  Card,
  Row,
  Col,
  Space,
  Button,
  Typography,
  Input,
  message,
} from 'antd';

import CustomTitle from '@components/Title';

import { SEJARAH } from '@constants/urls';

import fetchRequest from '@utils/fetcher';

const { Title, Text } = Typography;

interface UpsertCategoryCardProps {
  parentPage: string;
  pageName:
    | 'History'
    | 'VisionMission'
    | 'AstagatraLeadersForum'
    | 'Membership'
    | 'StudyAndConsultation'
    | 'Training'
    | 'Webinar'
    | 'Article'
    | 'Publication'
    | 'News'
    | 'Gallery';
}

const UpsertCategoryCard = (props: UpsertCategoryCardProps) => {
  const { pageName, parentPage } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [isError, setIsError] = useState(false);
  const [id, setId] = useState('');

  const handleUrlCheck = (value: string) => {
    let valueUrl;

    try {
      valueUrl = new URL(value);
    } catch (_) {
      setIsError(true);
      return;
    }

    if (valueUrl.hostname !== 'astagatra.com') {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  const handleSubmit = async () => {
    const method = id ? 'PATCH' : 'POST';

    const innerData = {
      name,
      type: 'QuickLink',
      pageName,
      linkTo: url,
    };

    const param = id
      ? {
          ...innerData,
          id,
        }
      : {
          categoryList: [innerData],
        };

    try {
      await fetchRequest({
        method: method,
        path: 'category',
        data: param,
      }).then((response) => {
        if (!response.error) {
          navigate(parentPage);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getQuickLinkData = async (id: string) => {
    try {
      await fetchRequest({ method: 'GET', path: 'category/id?id=' + id }).then(
        (response) => {
          if (!response.error) {
            setName(response.name || '');
            setUrl(response.linkTo || '');
          } else {
            message.error(response.error.message);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const quickLinkId = path[path.indexOf('edit') + 1];

      setId(quickLinkId);

      getQuickLinkData(quickLinkId);
    }
  }, []);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle name="Kategori Tambahan" url={SEJARAH} />
            <Title level={5}>Nama Kategori Tambahan</Title>
            <Input
              value={name}
              placeholder="Beranda"
              onChange={(e) => setName(e.target.value)}
              style={{ width: '100%' }}
            />
            <Title level={5}>Tautan Kategori Tambahan</Title>
            <Input
              placeholder="https://astagatra.com"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              onBlur={(e) => handleUrlCheck(e.target.value)}
              style={{ width: '100%' }}
            />
            {isError && (
              <Text type="danger">
                Hanya dapat memasukkan tautan halaman Astagatra
                (https://astagatra.com)
              </Text>
            )}
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default UpsertCategoryCard;
