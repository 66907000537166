import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, Modal, message } from 'antd';

import CategorySection from '@components/CategorySection';
import DeleteButton from '@components/DeleteButton';

import type { WebinarProps } from '@customTypes/info';
import type { QuickLinkProps } from '@customTypes/quickLink';

import fetchRequest from '@utils/fetcher';
import {
  ADD_KATEGORI_WEBINAR,
  ADD_WEBINAR,
  EDIT_DESC_WEBINAR,
  EDIT_KATEGORI_WEBINAR,
  EDIT_WEBINAR,
} from '@constants/urls';
import { ConsulProps } from '@customTypes/service';

const { Title } = Typography;

const Webinar = () => {
  const navigate = useNavigate();

  const [description, setDescription] = useState<ConsulProps[]>([]);
  const [webinarList, setWebinarList] = useState<WebinarProps[]>([]);
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [isModalOpen, setIsModalOpen] = useState({});

  const handleDelete = async (id: string) => {
    try {
      await fetchRequest({
        method: 'DELETE',
        path: 'event/webinar?id=' + id,
      }).then((response) => {
        if (!response.error) {
          getWebinarList();
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleModal = (id: string) => {
    const currentState = isModalOpen[id as keyof typeof isModalOpen];

    if (currentState === undefined) {
      setIsModalOpen({ [id]: true });
    } else {
      setIsModalOpen({ [id]: !currentState });
    }
  };

  const webinarColumns = [
    {
      title: 'Thumbnail',
      dataIndex: 'headerUrl',
      key: 'headerUrl',
      render: (text: string, record: WebinarProps) => (
        <>
          <Button onClick={() => handleModal(record.id)}>Lihat Foto</Button>
          <Modal
            title="Thumbnail"
            visible={isModalOpen[record.id as keyof typeof isModalOpen]}
            onCancel={() => handleModal(record.id)}
            footer={null}
          >
            <img src={text} alt="Thumbnail" />
          </Modal>
        </>
      ),
    },
    {
      title: 'Judul Webinar',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Deskripsi Webinar',
      dataIndex: 'content',
      key: 'content',
      render: (_: string, record: any) => <div dangerouslySetInnerHTML={{__html: record.content}}></div>
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_WEBINAR + '/' + record.id)}
          >
            Ubah
          </Button>
          <DeleteButton onDelete={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];

  const otherColumns = [
    {
      title: 'Konten',
      dataIndex: 'content',
      render: (text: string) => (
        <div dangerouslySetInnerHTML={{ __html: text }} />
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      dataIndex: 'id',
      render: (text: string) => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_DESC_WEBINAR)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];

  const getWebinarList = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'event/webinar' }).then(
        (response) => {
          if (!response.error) {
            setWebinarList(response || []);
          } else {
            message.error(response.error.message);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getQuickLinkList = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'category?types=QuickLink',
      }).then((response) => {
        if (!response.error) {
          setQuickLinkList(response.QuickLink || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getConsultationData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/service/webinar',
      }).then((response) => {
        if (!response.error) {
          setDescription(response.webinarMainDesc || {});
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getWebinarList();
    getQuickLinkList();
    getConsultationData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title={<Title level={4}>Deskripsi</Title>}>
        <Table
          columns={otherColumns}
          dataSource={[description]}
          pagination={false}
        />
      </Card>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Astagatra Webinar Series
            </Title>
            <Button onClick={() => navigate(ADD_WEBINAR)}>Tambah Baru</Button>
          </Space>
        }
      >
        <Table
          columns={webinarColumns}
          dataSource={webinarList}
          pagination={{
            defaultCurrent: 1,
            total: webinarList.length,
            pageSize: 5,
          }}
        />
      </Card>
      <CategorySection
        data={quickLinkList
          .filter((ql) => ql.pageName === 'Webinar')
          .map((ql, i) => ({ ...ql, key: i + 1 }))}
        addLinkUrl={ADD_KATEGORI_WEBINAR}
        editLinkUrl={EDIT_KATEGORI_WEBINAR}
        onRefetch={getQuickLinkList}
      />
    </Space>
  );
};

export default Webinar;
