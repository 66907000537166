import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, message } from 'antd';

import CategorySection from '@components/CategorySection';

import {
  ADD_KATEGORI_KAJIAN_DAN_KONSULTASI,
  EDIT_ADVISORY_BENEFIT,
  EDIT_ADVISORY_DESC,
  EDIT_ADVISORY_ITEM,
  EDIT_DESKRIPSI_KONSULTASI,
  EDIT_GAMBAR_KONSULTASI,
  EDIT_GAMBAR_KATALOG,
  EDIT_KATALOG_KAJIAN_DAN_KONSULTASI,
  EDIT_KENAPA_KAMI_DESC_KONSULTASI,
  EDIT_PENDEKATAN_DESC_KONSULTASI,
  EDIT_PENDEKETAN_ITEM_KONSULTASI,
  EDIT_STRATEGIC_BENEFIT,
  EDIT_STRATEGIC_DESC,
  EDIT_STRATEGIC_ITEM,
} from '@constants/urls';

import type { QuickLinkProps } from '@customTypes/quickLink';

import fetchRequest from '@utils/fetcher';
import { ConsulProps } from '@customTypes/service';
import PreviewImage from '@components/PreviewImage';

const { Title } = Typography;

const Consultation = () => {
  const [consulData, setConsulData] = useState<ConsulProps | {}>({});
  const [quickLinkList, setQuickLinkList] = useState<QuickLinkProps[]>([]);
  const [strategicBenefitData, setStrategicBenefitData] = useState<
    ConsulProps | {}
  >({});
  const [strategicDescData, setStrategicDescData] = useState<ConsulProps | {}>(
    {}
  );
  const [strategicItemData, setStrategicItemData] = useState<ConsulProps[]>([]);
  const [advisoryBenefit, setAdvisoryBenefit] = useState<ConsulProps | {}>({});
  const [advisoryDescData, setAdvisoryDescData] = useState<ConsulProps | {}>(
    {}
  );
  const [advisoryItemData, setAdvisoryItemData] = useState<ConsulProps[]>([]);
  const [mainPicture, setMainPicture] = useState<ConsulProps | {}>({});
  const [catalogMainPicture, setCatalogMainPicture] = useState<ConsulProps | {}>({});
  const [mainDescData, setMainDescData] = useState<ConsulProps | {}>({});
  const [whyUsDescData, setWhyUsDescData] = useState<ConsulProps | {}>({});
  const [approachDescData, setApproachDescData] = useState<ConsulProps | {}>({});
  const [approachItemData, setApproachItemData] = useState<ConsulProps[]>([]);

  const navigate = useNavigate();

  const consultationColumns = [
    {
      title: 'Konten Katalog',
      dataIndex: 'url',
      render: (_: string, record: any) => (
        <Button
          type="link"
          className="button-link-grey-border"
          style={{ width: '100%' }}
          onClick={() => window.open(record.url)}
        >
          Lihat Katalog
        </Button>
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      render: () => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_KATALOG_KAJIAN_DAN_KONSULTASI)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];

  const catalogPictureColumns = (url: string) =>  [
    {
      title: 'Icon',
      dataIndex: 'url',
      render: (_: string, record: any) => (
        <PreviewImage
          key="key"
          buttonName="Lihat Icon"
          mediaUrl={record.url}
        ></PreviewImage>
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      dataIndex: 'id',
      render: (text: string) => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(url + '/' + text)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
    ];

  const otherColumns = (url: string) => {
    return [
      {
        title: 'Konten',
        dataIndex: 'content',
        render: (text: string) => (
          <div dangerouslySetInnerHTML={{ __html: text }} />
        ),
      },
      {
        title: 'Aksi',
        key: 'action',
        dataIndex: 'id',
        render: (text: string) => (
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(url + '/' + text)}
          >
            Ubah
          </Button>
        ),
        width: 150,
      },
    ];
  };

  const pictureColumns = (url: string) =>  [
    {
      title: 'Icon',
      dataIndex: 'url',
      render: (_: string, record: any) => (
        <PreviewImage
          key="key"
          buttonName="Lihat Icon"
          mediaUrl={record.url}
        ></PreviewImage>
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      dataIndex: 'id',
      render: (text: string) => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(url + '/' + text)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
    ];

  const getConsultationData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/service/study-and-consul',
      }).then((response) => {
        if (!response.error) {
          setConsulData(response.studyAndConsultation || {});
          setQuickLinkList(response.quickLinks || []);
          setStrategicBenefitData(
            response.studyAndConsultationStrategicBenefit || {}
          );
          setStrategicItemData(
            response.studyAndConsultationStrategicItem || []
          );
          setStrategicDescData(
            response.studyAndConsultationStrategicDesc || {}
          );
          setAdvisoryBenefit(
            response.studyAndConsultationAdvisoryBenefit || {}
          );
          setAdvisoryItemData(response.studyAndConsultationAdvisoryItem || []);
          setAdvisoryDescData(response.studyAndConsultationAdvisoryDesc || []);
          setMainPicture(response.studyAndConsultationMainPicture || []);
          setCatalogMainPicture(response.studyAndConsultationCatalogMainPicture || []);
          setMainDescData(response.studyAndConsultationMainDesc || {});
          setWhyUsDescData(response.studyAndConsultationWhyUsDesc || {});
          setApproachDescData(response.studyAndConsultationMethodDesc || {});
          setApproachItemData(response.studyAndConsultationMethodItem || []);

        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getConsultationData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title={<Title level={4}>Katalog</Title>}>
        <Table
          columns={consultationColumns}
          dataSource={[consulData]}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Gambar Icon Katalog</Title>}>
        <Table
          columns={catalogPictureColumns(EDIT_GAMBAR_KATALOG)}
          dataSource={[catalogMainPicture]}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Gambar Kajian & Konsultasi</Title>}>
        <Table
          columns={pictureColumns(EDIT_GAMBAR_KONSULTASI)}
          dataSource={[mainPicture]}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Deskripsi Kajian & Konsultasi</Title>}>
        <Table
          columns={otherColumns(EDIT_DESKRIPSI_KONSULTASI)}
          dataSource={[mainDescData]}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Deskripsi Kenapa Kami</Title>}>
        <Table
          columns={otherColumns(EDIT_KENAPA_KAMI_DESC_KONSULTASI)}
          dataSource={[whyUsDescData]}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Deskripsi Pendeketan</Title>}>
        <Table
          columns={otherColumns(EDIT_PENDEKATAN_DESC_KONSULTASI)}
          dataSource={[approachDescData]}
          pagination={false}
        />
      </Card>
      <Card title={<Title level={4}>Metode Pendekatan</Title>}>
        <Table
          columns={otherColumns(EDIT_PENDEKETAN_ITEM_KONSULTASI)}
          dataSource={approachItemData}
          pagination={false}
        />
      </Card>
      <Card
        title={
          <Title level={4}>
            Konsultasi Eksekutif Penguatan Organisasi Publik
          </Title>
        }
      >
        <Table
          columns={otherColumns(EDIT_STRATEGIC_DESC)}
          dataSource={[strategicDescData]}
          pagination={false}
        />
      </Card>
      <Card
        title={<Title level={4}>Benefit Penguatan Organisasi Publik</Title>}
      >
        <Table
          columns={otherColumns(EDIT_STRATEGIC_BENEFIT)}
          dataSource={[strategicBenefitData]}
          pagination={false}
        />
      </Card>
      <Card
        title={
          <Title level={4}>
            Bidang Konsultasi Pengembangan Bisnis Perusahaan Penguatan
            Organisasi Publik
          </Title>
        }
      >
        <Table
          columns={otherColumns(EDIT_STRATEGIC_ITEM)}
          dataSource={strategicItemData}
          pagination={false}
        />
      </Card>
      <Card
        title={
          <Title level={4}>Konsultasi Pengembangan Bisnis Perusahaan</Title>
        }
      >
        <Table
          columns={otherColumns(EDIT_ADVISORY_DESC)}
          dataSource={[advisoryDescData]}
          pagination={false}
        />
      </Card>
      <Card
        title={<Title level={4}>Benefit Pengembangan Bisnis Perusahaan</Title>}
      >
        <Table
          columns={otherColumns(EDIT_ADVISORY_BENEFIT)}
          dataSource={[advisoryBenefit]}
          pagination={false}
        />
      </Card>
      <Card
        title={
          <Title level={4}>
            Bidang Konsultasi Pengembangan Bisnis Perusahaan
          </Title>
        }
      >
        <Table
          columns={otherColumns(EDIT_ADVISORY_ITEM)}
          dataSource={advisoryItemData}
          pagination={false}
        />
      </Card>
      <CategorySection
        data={quickLinkList
          .filter((ql) => ql.pageName === 'StudyAndConsultation')
          .map((ql, i) => ({ ...ql, key: i + 1 }))}
        addLinkUrl={ADD_KATEGORI_KAJIAN_DAN_KONSULTASI}
        editLinkUrl={EDIT_KATALOG_KAJIAN_DAN_KONSULTASI}
        onRefetch={getConsultationData}
      />
    </Space>
  );
};

export default Consultation;
