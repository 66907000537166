import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, message } from 'antd';

import { EDIT_MANAJEMEN } from '@constants/urls';

import type { ManagementProps } from '@customTypes/aboutUs';

import fetchRequest from '@utils/fetcher';
import PreviewImage from '@components/PreviewImage';

const { Title } = Typography;

const Management = () => {
  const [managementData, setManagementData] = useState<ManagementProps[]>([]);
  const navigate = useNavigate();

  const managementColumns = [
    {
      title: 'No',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Jabatan',
      dataIndex: 'otherData',
      render: (_: string, record: any) => <>{record.otherData.position}</>,
    },
    {
      title: 'Foto',
      dataIndex: 'url',
      render: (_: string, record: any) => (
        <PreviewImage
          key="key"
          buttonName="Lihat Foto"
          mediaUrl={record.url}
        ></PreviewImage>
      ),
    },
    {
      title: 'Nama',
      dataIndex: 'title',
    },
    {
      title: 'Narasi',
      dataIndex: 'content',
      render: (_: string, record: any) => <div dangerouslySetInnerHTML={{__html: record.content}}></div>,
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Button
          type="link"
          className="button-link-grey"
          onClick={() => navigate(EDIT_MANAJEMEN + '/' + record.id)}
        >
          Ubah
        </Button>
      ),
      width: 150,
    },
  ];

  const getManagementData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/about-us/management',
      }).then((response) => {
        if (!response.error) {
          const order = ["Executive Director","General Affairs Manager" , "Research and Consulting Manager" , "Executive Learning Manager" ,"Businesses and Organization Development Manager"]
          let newManagement = [] as any
          
          for (const eltOrder of order ) {
            newManagement.push(response.management?.find((r:any) => r?.otherData?.position === eltOrder ))
          }
          setManagementData(newManagement || {});
          
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getManagementData();
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title={<Title level={4}>Manajemen</Title>}>
        <Table
          columns={managementColumns}
          dataSource={managementData}
          pagination={false}
        />
      </Card>
    </Space>
  );
};

export default Management;
