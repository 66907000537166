import { useNavigate } from 'react-router';

import { Space, Card, Table, Button, Typography } from 'antd';

import { HomepageProps } from '@customTypes/dashboard';

import { ADD_CAROUSEL, EDIT_CAROUSEL } from '@constants/urls';

const { Title } = Typography;

const CarouselCard = ({ data }: { data: HomepageProps[] }) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Section',
      key: 'otherData',
      dataIndex: 'otherData',
      render: (text: { section: string }) => text?.section,
    },
    {
      title: 'Judul Konten',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Deskripsi Konten',
      key: 'content',
      dataIndex: 'content',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_CAROUSEL + '/' + record.id)}
          >
            Ubah
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Carousel
            </Title>
            {data.length < 3 && (
              <Button onClick={() => navigate(ADD_CAROUSEL)}>
                Tambah Baru
              </Button>
            )}
          </Space>
        }
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </Card>
    </Space>
  );
};

export default CarouselCard;
