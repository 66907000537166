import { useEffect, useState } from 'react';

import {
  Input,
  Row,
  Col,
  Card,
  Space,
  Typography,
  Button,
  message,
} from 'antd';

import CustomTitle from '@components/Title';

import { VISI_MISI } from '@constants/urls';
import { useNavigate } from 'react-router-dom';
import fetchRequest from '@utils/fetcher';

const { TextArea } = Input;
const { Title } = Typography;

const EditVision = () => {
  const [content, setContent] = useState('');
  const navigate = useNavigate();

  const getVisionData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource/about-us/vision-mission',
      }).then((response) => {
        if (!response.error) {
          const visionData = response.vision || {};
          setContent(visionData.content || '');
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleSubmit = async () => {
    try {
      await fetchRequest({
        method: 'POST',
        path: 'resource/about-us/vision-mission/vision',
        data: { content },
      }).then((response) => {
        if (!response.error) {
          navigate(VISI_MISI);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getVisionData();
  }, []);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle name="Visi" url={VISI_MISI} />
            <Title level={5}>Konten Visi</Title>
            <TextArea
              rows={4}
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditVision;
