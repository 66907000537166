import { useState } from 'react';

import { Popover, Button, Space, Typography } from 'antd';

const { Text } = Typography;

interface DeleteButtonProps {
  onDelete: () => void;
}

const DeleteButton = (props: DeleteButtonProps) => {
  const { onDelete } = props;

  const [isVisible, setIsVisible] = useState(false);

  const handleDelete = () => {
    setIsVisible(false);
    onDelete();
  };

  return (
    <Popover
      title="Hapus"
      trigger="click"
      visible={isVisible}
      content={
        <Space direction="vertical">
          <Text>Ingin menghapus data ini?</Text>
          <Space direction="horizontal">
            <Button onClick={() => setIsVisible(false)}>Tidak</Button>
            <Button onClick={() => handleDelete()} danger type="primary">
              Ya
            </Button>
          </Space>
        </Space>
      }
    >
      <Button
        type="link"
        className="button-link-red"
        onClick={() => setIsVisible(true)}
      >
        Hapus
      </Button>
    </Popover>
  );
};

export default DeleteButton;
