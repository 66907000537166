import { useState } from 'react';
import { useNavigate } from 'react-router';

import {
  Row,
  Col,
  Card,
  Space,
  Typography,
  Button,
  message,
  Avatar,
  Tooltip,
  Upload,
} from 'antd';

import { UploadOutlined, InfoOutlined } from '@ant-design/icons';

import CustomTitle from '@components/Title';

import { KAJIAN_DAN_KONSULTASI } from '@constants/urls';

import { fetchFormData } from '@utils/fetcher';

const { Title } = Typography;

const EditCatalogConsultation = () => {
  const [fileUrl, setFileUrl] = useState<any[]>([]);
  const navigate = useNavigate();

  const handleFileUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileUrl(fileList);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    if (fileUrl) {
      formData.append(
        'file',
        fileUrl[0]?.originFileObj || fileUrl[0]?.url
      );
    }

    try {
      await fetchFormData({
        method: 'POST',
        path: 'resource/service/study-and-consul/catalog',
        data:  formData,
      }).then((response) => {
        if (!response.error) {
          navigate(KAJIAN_DAN_KONSULTASI);
        } else {
          if (Array.isArray(response.message)) {
            response.message.map((msg: string) => message.error(msg));
          } else {
            message.error(response.message);
          }
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space align="baseline">
              <CustomTitle name="Katalog Layanan" url={KAJIAN_DAN_KONSULTASI} />
              <Tooltip title="Menerima jenis file: .pdf. Unggah sertifikat dalam bentuk file .pdf maksimal 25mb.">
                <Avatar
                  size={'small'}
                  icon={<InfoOutlined />}
                  style={{ backgroundColor: '#8C8C8C' }}
                ></Avatar>
              </Tooltip>
            </Space>
            <Title level={5}>Katalog</Title>
            <Upload
              listType="picture"
              maxCount={1}
              accept=".pdf"
              fileList={fileUrl}
              onChange={handleFileUpload}
              beforeUpload={()=> false}
            >
              <Button icon={<UploadOutlined />}>Unggah Katalog</Button>
            </Upload>
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditCatalogConsultation;
