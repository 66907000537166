import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import CryptoJS from 'crypto-js';

import {
  PageHeader,
  Row,
  Col,
  Space,
  Typography,
  Avatar,
  Button,
  Dropdown,
  Menu,
  message,
} from 'antd';

import { UserOutlined, DownOutlined } from '@ant-design/icons';

import imgLogo from '@assets/logo.svg';

import { useGlobalState } from '@contexts/globalState';

import { fetchAuthRequest } from '@utils/fetcher';

import './styles.css';
import { AUTH, CHANGE_PASSWORD } from '@constants/urls';

const { Title } = Typography;

const CustomNavbar = () => {
  const navigate = useNavigate();
  const { loginInfo, setLoginInfo } = useGlobalState();
  const cookies = new Cookies();

  const fetchLogout = async () => {
    try {
      await fetchAuthRequest({ method: 'POST', path: 'member/logout' }).then(
        (response) => {
          if (response['$metadata']) {
            navigate(AUTH);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const handleLogout = () => {
    fetchLogout();

    cookies.remove('admin-r-token', { path: '/' });
    cookies.remove('admin-token', { path: '/' });
    cookies.remove('AE', { path: '/' });

    setLoginInfo({
      isLogin: false,
      username: '',
      email: '',
      refreshToken: '',
      expireAt: new Date(),
    });

    navigate(AUTH);
  };

  const handleCheckToken = useCallback(async () => {
    const emailFromCookie = cookies.get('AE') || '';
    const refreshTokenFromCookie = cookies.get('admin-r-token') || '';

    const decryptedEmail = emailFromCookie
      ? CryptoJS.AES.decrypt(emailFromCookie, 'user-email').toString(
          CryptoJS.enc.Utf8
        )
      : '';

    const bodyParam = {
      email: loginInfo.email || decryptedEmail,
      refreshToken: loginInfo.refreshToken || refreshTokenFromCookie,
    };

    try {
      await fetchAuthRequest({
        method: 'POST',
        path: 'auth/refresh-token',
        data: bodyParam,
      }).then((response) => {
        const exp = response.accessToken.payload.exp;

        setLoginInfo({
          isLogin: true,
          username: 'Member',
          email: response.idToken.payload.email,
          refreshToken: response.refreshToken.token,
          expireAt: new Date(exp * 1000),
        });
        cookies.set('admin-token', response.accessToken.jwtToken, {
          path: '/',
          expires: new Date(exp * 1000),
        });
      });
    } catch (error) {
      navigate(AUTH);
      setLoginInfo({
        isLogin: false,
        username: '',
        email: '',
        refreshToken: '',
        expireAt: new Date(),
      });
      cookies.remove('admin-r-token', { path: '/' });
      cookies.remove('admin-token', { path: '/' });
      cookies.remove('AE', { path: '/' });
    }
  }, []);

  useEffect(() => {
    if (loginInfo.isLogin) {
      const currentDate = new Date();

      if (currentDate >= loginInfo.expireAt) {
        handleCheckToken();
      }
    }

    if (!loginInfo.isLogin && cookies.get('admin-r-token')) {
      handleCheckToken();
    }

    if (
      !loginInfo.isLogin &&
      window.location.pathname.match('/') &&
      !cookies.get('admin-r-token')
    ) {
      navigate(AUTH);
    }
  }, [window.location.pathname]);

  return (
    <PageHeader className="styNavbar" ghost={false}>
      <Row align="middle">
        <Col span={8}>
          <img src={imgLogo} alt="Astagatra" />
        </Col>
        <Col span={4} offset={12} className="styIdentityCol">
          <Space align="center">
            <Title level={5} className="styName">
              Admin
            </Title>
            <Avatar icon={<UserOutlined />} />
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      onClick={() => navigate(CHANGE_PASSWORD)}
                      type="link"
                    >
                      Ganti Password
                    </Button>
                  </Menu.Item>
                  <Menu.Item>
                    <Button onClick={() => handleLogout()} type="link">
                      Keluar
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <Button
                type="text"
                icon={<DownOutlined style={{ color: 'white' }} />}
              />
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </PageHeader>
  );
};

export default CustomNavbar;
