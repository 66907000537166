import { Route, Routes } from 'react-router';

import { AUTH } from '@constants/urls';

import Login from './Login';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
    </Routes>
  );
};

export default AuthRoutes;
