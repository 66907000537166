import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import {
  Card,
  Row,
  Col,
  Space,
  Button,
  message,
  Input,
  Typography,
  Select,
} from 'antd';

import CustomTitle from '@components/Title';

import { DASHBOARD } from '@constants/urls';

import fetchRequest from '@utils/fetcher';

const { TextArea } = Input;
const { Title } = Typography;
const { Option } = Select;

type TitleProps = {
  id: string;
  title: string;
  type: 'news' | 'article' | 'publication';
};

const EditCarousel = () => {
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [articleList, setArticleList] = useState<TitleProps[]>([]);
  const [newsList, setNewsList] = useState<TitleProps[]>([]);
  const [publicationList, setPublicationList] = useState<TitleProps[]>([]);
  const [section, setSection] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async () => {
    const titleId = [...articleList, ...newsList, ...publicationList].filter(
      (al) => al.id === title
    )[0];

    let sec = section;

    if (section === '') {
      if (titleId.type === 'news') {
        sec = 'BERITA';
      } else if (titleId.type === 'article') {
        sec = 'ARTIKEL';
      } else if (titleId.type === 'publication') {
        sec = 'PUBLIKASI';
      }
    }

    const param = {
      title: titleId.title,
      content,
      section: sec,
      originId: title,
    };

    try {
      await fetchRequest({
        method: 'POST',
        path: 'resource/homepage/carousel',
        data: id !== '' ? { ...param, id } : param,
      }).then((response) => {
        if (!response.error) {
          navigate(DASHBOARD);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const getContent = async (id: string) => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'resource?id=' + id,
      }).then((response) => {
        if (!response.error) {
          setTitle(response.title || '');
          setContent(response.content || '');
          setSection(response?.otherData?.section || '');
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const getTitleList = async (type: string) => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'info/no-content?types=' + type,
      }).then((response) => {
        if (!response.error) {
          if (type === 'news') {
            setNewsList(response || []);
          } else if (type === 'article') {
            setArticleList(response || []);
          } else if (type === 'publication') {
            setPublicationList(response || []);
          }
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const getDataList = async () => {
    let type = '';

    if (section === 'ARTIKEL ') {
      type = 'article';
    } else if (section === 'PUBLIKASI ') {
      type = 'publication';
    } else if (section === 'BERITA ') {
      type = 'news';
    }

    if (type === '') {
      getTitleList('news');
      getTitleList('publication');
      getTitleList('article');
    } else {
      getTitleList(type);
    }
  };

  useEffect(() => {
    const isEdit =
      location.pathname.split('/').filter((pn) => pn === 'edit').length > 0;

    if (isEdit) {
      const path = location.pathname.split('/');
      const testimonyId = path[path.indexOf('edit') + 1];

      setId(testimonyId);

      getContent(testimonyId);
    }

    getDataList();
  }, []);
  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle name={section || 'Carousel'} url={DASHBOARD} />
            <Title level={5}>Pilih judul yang akan ditampilkan</Title>
            <Select
              value={title}
              placeholder="Judul"
              onChange={(value: any) => setTitle(value)}
              style={{ width: '100%' }}
            >
              {[...articleList, ...newsList, ...publicationList].map((al) => (
                <Option key={al.id} value={al.id}>
                  {al.title}
                </Option>
              ))}
            </Select>
            <Title level={5}>Deskripsi Singkat</Title>
            <TextArea
              value={content}
              onChange={(e: any) => setContent(e.target.value)}
              rows={4}
            />
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default EditCarousel;