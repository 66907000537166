import { Button, Image, Space } from 'antd';
import { useState } from 'react';

interface CategorySectionProps {
  buttonName: string;
  mediaUrl: string;
}

const PreviewImage = (props: CategorySectionProps) => {
  const { buttonName, mediaUrl } = props;
  const [visible, setVisible] = useState(false);

  return (
    <Space>
      <Button
        type="primary"
        className="button-link-black"
        onClick={() => setVisible(true)}
      >
        {buttonName}
      </Button>
      <Image
        style={{ display: 'none' }}
        preview={{
          visible,
          src: mediaUrl,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </Space>
  );
};

export default PreviewImage;
