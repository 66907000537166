import { useState } from 'react';

import { Modal, Row, Col, Button, Typography, Radio, Space } from 'antd';

const { Text, Title } = Typography;

interface Props {
  isOpen: boolean;
  onSubmit: (year: number) => void;
  onClose: () => void;
}

const YEAR_LIST = [
  {
    name: '1 Tahun',
    value: 1,
  },
  {
    name: '2 Tahun',
    value: 2,
  },
  {
    name: 'Tanpa Batas',
    value: 999,
  },
];

const RequestModal = (props: Props) => {
  const { isOpen, onClose, onSubmit } = props;
  const [year, setYear] = useState(0);

  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={
        <Row gutter={[8, 8]}>
          <Col>
            <Button onClick={onClose}>Batal</Button>
          </Col>
          <Col>
            <Button danger type="primary" onClick={() => onSubmit(year)}>
              Lanjut
            </Button>
          </Col>
        </Row>
      }
    >
      <Title>Pilih Waktu Perpanjangan</Title>
      <Space direction="vertical">
        <Text>Pilih masa waktu perpanjangan</Text>
        <Radio.Group onChange={(e) => setYear(e.target.value)}>
          <Space direction="vertical">
            {YEAR_LIST.map((yl) => (
              <Radio key={yl.value} value={yl.value}>
                {yl.name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Space>
    </Modal>
  );
};

export default RequestModal;
