import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import App from './routes';

import { CookiesProvider } from 'react-cookie';

import { GlobalProvider } from '@contexts/globalState';

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <GlobalProvider>
        <App />
      </GlobalProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
