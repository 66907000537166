import { useState } from 'react';
import { useNavigate } from 'react-router';

import { Space, Card, Table, Button, Typography, Modal } from 'antd';

import type { HomepageProps } from '@customTypes/dashboard';
import { EDIT_LOGIN_BANNER } from '@constants/urls';

const { Title } = Typography;

const LoginCard = (data: HomepageProps) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns = [
    {
      title: 'Foto Banner',
      key: 'url',
      dataIndex: 'url',
      render: (text: string) => (
        <>
          <Button onClick={() => setIsModalOpen(true)}>Lihat Foto</Button>
          <Modal
            onCancel={() => setIsModalOpen(false)}
            visible={isModalOpen}
            footer={null}
          >
            <img
              src={text}
              alt="Foto Banner"
              style={{ width: '-webkit-fill-available' }}
            />
          </Modal>
        </>
      ),
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_LOGIN_BANNER + '/' + record.id)}
          >
            Ubah
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Login Banner
            </Title>
          </Space>
        }
      >
        <Table columns={columns} dataSource={[data]} pagination={false} />
      </Card>
    </Space>
  );
};

export default LoginCard;
