import { useState, useEffect } from 'react';

import { message, Space } from 'antd';

import fetchRequest from '@utils/fetcher';

import { MemberType, MemberRequestType } from '@customTypes/member';

import ActiveMemberCard from './ActiveMemberCard';
import ReqeustMemberCard from '../../../components/RequestMemberCard';

const MemberManagement = () => {
  const [memberData, setMemberData] = useState<MemberType[]>([]);
  const [extendRequestData, setExtendRequestData] = useState<
    MemberRequestType[]
  >([]);

  const getMemberData = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'user-member/all' }).then(
        (response) => {
          if (!response.error) {
            setMemberData(response || []);
          } else {
            message.error(response.error.message);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const getExtendRequestData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'user-member/membership-request?type=EXTEND',
      }).then((response) => {
        if (!response.error) {
          setExtendRequestData(response || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getMemberData();
    getExtendRequestData();
  }, []);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <ActiveMemberCard data={memberData} onRefresh={getMemberData} />
      <ReqeustMemberCard
        data={extendRequestData}
        onRefresh={getExtendRequestData}
        type="EXTEND"
      />
    </Space>
  );
};

export default MemberManagement;
