import React, { createContext, useContext, useState } from 'react';

type LoginProps = {
  isLogin: boolean;
  username: string;
  email: string;
  refreshToken: string;
  expireAt: Date;
};


export const GlobalContext = createContext({
  loginInfo: {} as LoginProps,
  setLoginInfo: {} as React.Dispatch<React.SetStateAction<LoginProps>>,
});


const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [loginInfo, setLoginInfo] = useState<LoginProps>({
    isLogin: false,
    username: '',
    email: '',
    refreshToken: '',
    expireAt: new Date(),
  });

  return (
    <GlobalContext.Provider
      value={{
        loginInfo,
        setLoginInfo,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

const useGlobalState = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalContext');
  }
  return context;
};

export { GlobalProvider, useGlobalState };