import imgDashboard from '@assets/menus/dashboard.svg';
import imgAboutUs from '@assets/menus/about-us.svg';
import imgContact from '@assets/menus/contact.svg';
import imgMember from '@assets/menus/member.svg';
import imgProduct from '@assets/menus/product.svg';
import imgService from '@assets/menus/service.svg';
import imgNewsletter from '@assets/menus/newsletter.svg';

export const MENU_ICON = {
  ['Dashboard']: imgDashboard,
  ['Tentang Kami']: imgAboutUs,
  ['Kontak']: imgContact,
  ['Member']: imgMember,
  ['Produk Kami']: imgProduct,
  ['Layanan']: imgService,
  ['Newsletter']: imgNewsletter,
};
