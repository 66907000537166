import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Space, Card, Table, Button, Typography, Tag, message } from 'antd';

import { ADD_ACARA, EDIT_ACARA } from '@constants/urls';

import DeleteButton from '@components/DeleteButton';

import type { EventProps } from '@customTypes/info';

import fetchRequest from '@utils/fetcher';

const { Title, Text } = Typography;

const Event = () => {
  const navigate = useNavigate();

  const [eventList, setEventList] = useState<EventProps[]>([]);

  const handleDelete = async (id: string) => {
    try {
      await fetchRequest({ method: 'DELETE', path: 'event?id=' + id }).then(
        (response) => {
          if (!response.error) {
            getEventList();
          } else {
            message.error(response.error.message);
          }
        }
      );
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const eventColumns = [
    {
      title: 'Tanggal Acara',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Waktu',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Kegiatan',
      dataIndex: 'type',
      key: 'type',
      render: (_: string, record: EventProps) => {
        switch (record.type) {
          case 'study-consultation':
            return <Tag color="red">{`Kajian & Konsultasi`}</Tag>;
          case 'webinar':
            return <Tag color="red">Webinar</Tag>;
          case 'training':
            return <Tag color="red">Pelatihan</Tag>;
          default:
            break;
        }
      },
    },
    {
      title: 'Nama Acara',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      render: (text: string) => <Text underline>{text}</Text>,
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_ACARA + '/' + record.id)}
          >
            Ubah
          </Button>
          <DeleteButton onDelete={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];

  const getEventList = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'event' }).then((response) => {
        if (!response.error) {
          setEventList(response || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getEventList();
  }, []);

  return (
    <Card
      title={
        <Space align="end">
          <Title level={4} style={{ marginBottom: 0 }}>
            Acara
          </Title>
          <Button onClick={() => navigate(ADD_ACARA)}>Tambah Baru</Button>
        </Space>
      }
    >
      <Table
        columns={eventColumns}
        dataSource={eventList}
        pagination={{
          defaultCurrent: 1,
          total: eventList.length,
          pageSize: 5,
        }}
      />
    </Card>
  );
};

export default Event;
