import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Card, Typography, Button, Space, Table, Popover, message } from 'antd';

import { NewsletterProps } from '@customTypes/newsletter';

import fetchRequest from '@utils/fetcher';
import { ADD_NEWSLETTER } from '@constants/urls';

const { Title, Text } = Typography;

const ContentCard = () => {
  const navigate = useNavigate();

  const [content, setContent] = useState<NewsletterProps[]>([]);

  const getData = async () => {
    try {
      await fetchRequest({
        method: 'GET',
        path: 'newsletter?types=Content',
      }).then((response) => {
        if (!response.error) {
          setContent(response || []);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSend = async (id: string) => {
    try {
      await fetchRequest({
        method: 'POST',
        path: 'newsletter/send?id=' + id,
      }).then((response) => {
        if (!response.error) {
          message.success('Newsletter telah berhasil dikirim.');
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: 'Tanggal Terakhir Mengirim',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => <Text>{dayjs(text).format('YYYY-MM-DD')}</Text>,
    },
    {
      title: 'Judul',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string) => {
        switch (text) {
          case 'Completed':
            return 'Terkirim';
          case 'NotSent':
            return 'Belum dikirim';
          case 'Ongoing':
            return 'Sedang dikirim';
          default:
            break;
        }
      },
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: NewsletterProps) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => handleSend(record.id)}
          >
            Kirim
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={
        <Space align="end">
          <Title level={4} style={{ marginBottom: 0 }}>
            Newsletter
          </Title>
          <Button onClick={() => navigate(ADD_NEWSLETTER)}>Tambah Baru</Button>
        </Space>
      }
    >
      <Table
        columns={columns}
        dataSource={content}
        pagination={{
          defaultCurrent: 1,
          total: content.length,
          pageSize: 5,
        }}
      />
    </Card>
  );
};

export default ContentCard;
