import { useState } from 'react';
import { useNavigate } from 'react-router';

import { Space, Card, Table, Button, Typography, Modal,message } from 'antd';

import { HomepageProps } from '@customTypes/dashboard';

import DeleteButton from '@components/DeleteButton';

import { ADD_KEUNGGULAN_KAMI, EDIT_KEUNGGULAN_KAMI } from '@constants/urls';

const { Title } = Typography;

import fetchRequest from '@utils/fetcher';

interface Props {
  data: HomepageProps[];
  onDelete: () => void;
}

const StrengthCard = (props: Props) => {
  const { data, onDelete } = props;

  
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState({});



  const handleDelete = async (id: string) => {
    try {
      await fetchRequest({
        method: 'DELETE',
        path: 'resource?id=' + id,
      }).then((response) => {
        if (!response.error) {
          onDelete();
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error: any) {
      message.error(error.message);
    }
    
  };

  const handleModal = (id: string) => {
    const currentModal = isModalOpen[id as keyof typeof isModalOpen];

    if (currentModal === undefined) {
      setIsModalOpen({ [id]: true });
    } else {
      setIsModalOpen({ [id]: !currentModal });
    }



  }


  
  const columns = [
    {
      title: 'Icon',
      key: 'url',
      dataIndex: 'url',
      render: (text: string) => (
        <>
          <Button onClick={() => handleModal(text)}>Lihat Foto</Button>
          <Modal
            onCancel={() => handleModal(text)}
            visible={isModalOpen[text as keyof typeof isModalOpen]}
            footer={null}
          >
            <img src={text} alt="Icon" />
          </Modal>
        </>
      ),
    },
    {
      title: 'Produk',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Deskripsi',
      key: 'content',
      dataIndex: 'content',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (_: string, record: any) => (
        <Space align="end">
          <Button
            type="link"
            className="button-link-grey"
            onClick={() => navigate(EDIT_KEUNGGULAN_KAMI + '/' + record.id)}
          >
            Ubah
          </Button>
          <DeleteButton onDelete={() => handleDelete(record.id)} />
        </Space>
      ),
    },
  ];
  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card
        title={
          <Space align="end">
            <Title level={4} style={{ marginBottom: 0 }}>
              Produk Kami
            </Title>
              <Button onClick={() => navigate(ADD_KEUNGGULAN_KAMI)}>
                Tambah Baru
              </Button>
          </Space>
        }
      >
        <Table columns={columns} dataSource={data} pagination={false} />
      </Card>
    </Space>
  );
};

export default StrengthCard;
