import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import ReactQuill from 'react-quill';

import {
  Card,
  Row,
  Col,
  Space,
  Typography,
  Input,
  Button,
  Select,
  Upload,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { WEBINAR } from '@constants/urls';

import type { EventProps, WebinarProps } from '@customTypes/info';

import CustomTitle from '@components/Title';
import fetchRequest, { fetchFormData } from '@utils/fetcher';

const { Title } = Typography;
const { Option } = Select;

const UpsertWebinar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [header, setHeader] = useState<any[]>([]);
  const [webinarList, setWebinarList] = useState<WebinarProps[]>([]);
  const [id, setId] = useState('');
  const [name, setName] = useState('');

  const handleImageUpload = async (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setHeader(fileList);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('id', id);
    if (header) {
      formData.append('file', header[0]?.originFileObj || header[0]?.url);
    }

    try {
      await fetchFormData({
        method: 'POST',
        path: 'event/webinar',
        data: formData,
      }).then((response) => {
        if (!response.error) {
          navigate(WEBINAR);
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  const getWebinarList = async () => {
    try {
      await fetchRequest({ method: 'GET', path: 'event' }).then((response) => {
        if (!response.error) {
          const webinars = response.filter(
            (r: EventProps) => r.type === 'webinar'
          );
          setWebinarList(webinars || []);

          const isEdit =
            location.pathname.split('/').filter((pn) => pn === 'edit').length >
            0;

          if (isEdit) {
            const path = location.pathname.split('/');
            const webinarId = path[path.indexOf('edit') + 1];

            setId(webinarId);

            const currentData = response.filter(
              (dt: WebinarProps) => dt.id === webinarId
            )[0];

            setName(currentData.name);

            if (currentData.headerUrl) {
              setHeader([
                {
                  uid: '-1',
                  name: currentData.id + '.png',
                  status: 'done',
                  url: currentData.headerUrl,
                },
              ]);
            }
          }
        } else {
          message.error(response.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    getWebinarList();
  }, []);

  return (
    <Row>
      <Col span={12} offset={6}>
        <Card>
          <Space direction="vertical" style={{ width: '100%' }}>
            <CustomTitle
              name="Webinar"
              url={WEBINAR}
              toolTip="Menerima jenis file .jpg, .jpeg, dan .png. Total maksimal file yang dapat diunggah adalah 25mb."
            />
            <Title level={5}>Thumbnail</Title>
            <Upload
              listType="picture"
              maxCount={1}
              accept=".png, .jpg, .jpeg"
              fileList={header}
              onChange={handleImageUpload}
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Title level={5}>Judul Webinar</Title>
            <Select
              defaultValue={id}
              onChange={(value) => setId(value)}
              style={{ width: '100%' }}
              placeholder="Pilih Judul Webinar"
              value={id}
            >
              {webinarList.map((wl) => (
                <Option key={wl.id}>{wl.name}</Option>
              ))}
            </Select>
            <Row justify="end">
              <Col>
                <Button type="primary" danger onClick={() => handleSubmit()}>
                  Simpan Perubahan
                </Button>
              </Col>
            </Row>
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default UpsertWebinar;
